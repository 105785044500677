<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <form-loader
      v-if="configLoaded"
      :on-save-listeners="['hoseConfigurationUpdated']"
      class="px-4"
      :form-type="formType"
      :title="formTitle"
      :item-transform="itemTransform"
      v-on:itemUpdated="onItemUpdated"
      :before-save="beforeSave"
      :id="hoseConfigurationId"
      :initial-item="clientAdmin ? {} : {organisationId : organisation.id}"
      namespace="hoseConfigurations">
    <template #default="{item,errors,onUpdate}">
      <v-alert type="warning" icon="mdi-alert-circle-outline" dark dense v-if="item.hoseCount">
        <div class="flex-fill">Any changes made to this hose configuration will effect all {{ item.hoseCount }} hoses
          associated with it
        </div>
      </v-alert>
      <item-list-loader
          v-if="clientAdmin"
          namespace="organisations"
          :limit="1000"
      >
        <template v-slot:main="orgSlot">
          <v-select
              :hide-details="true"
              v-model="item.organisationId"
              :items="orgSlot.items"
              item-text="name"
              item-value="id"
              label="Select organisation"
              :error="errors.organisationId != null"
              :error-messages="errors.organisationId"
          ></v-select>
        </template>
      </item-list-loader>
      <v-text-field
          label="Configuration name"
          v-model="item.name"
          :error="errors.name != null"
          :error-messages="errors.name"
      />
      <manual-entry
          :forced-manual-entry="currentClient.manualHoseConfiguration"
          label="Hose type"
          :initial-manual-entry="formType !== 'add' && isUUID(item.type) === false"
          v-model="item.type"
          :errors="errors.type">
        <v-autocomplete
            label="Hose type"
            v-model="item.type"
            :items="filteredHoseTypes"
            :clearable="true"
            item-value="id"
            item-text="name"
            @input="onUpdate(item)"
            append-icon="mdi-chevron-down"
            clear-icon="mdi-close-circle"
            :error="errors.type != null"
            :error-messages="errors.type"
        />
      </manual-entry>

      <manual-entry
          :forced-manual-entry="currentClient.manualHoseConfiguration"
          :initial-manual-entry="formType !== 'add' && isUUID(item.boreSize) === false"
          label="Bore size"
          v-model="item.boreSize"
          :errors="errors.boreSize">
        <v-autocomplete
            label="Bore size"
            v-model="item.boreSize"
            :items="filteredBoreSizes"
            :clearable="true"
            item-value="id"
            item-text="name"
            @input="onUpdate(item)"
            append-icon="mdi-chevron-down"
            clear-icon="mdi-close-circle"
            :error="errors.boreSize != null"
            :error-messages="errors.boreSize"
        >
        </v-autocomplete>
      </manual-entry>

      <v-text-field
          label="Display Pressure /bar"
          v-model="displayPressure"
          type="number"
          suffix="bar"
          :error="errors.displayPressure != null"
          :error-messages="errors.displayPressure"
      />

      <v-text-field
          label="Hose length (overall) /mm"
          v-model="item.length"
          type="number"
          suffix="mm"
          :error="errors.length != null"
          :error-messages="errors.length"
      />

      <manual-entry
          :forced-manual-entry="currentClient.manualHoseConfiguration"
          :initial-manual-entry="formType !== 'add' && isUUID(item.connection1) === false"
          label="Connection 1"
          v-model="item.connection1"
          @input="onUpdate(item)"
          :errors="errors.connection1">
        <v-autocomplete
            label="Connection 1"
            v-model="item.connection1"
            @input="onUpdate(item)"
            :items="filteredConnections"
            :clearable="true"
            item-value="id"
            item-text="name"
            append-icon="mdi-chevron-down"
            clear-icon="mdi-close-circle"
            :error="errors.connection1 != null"
            :error-messages="errors.connection1"
        >
        </v-autocomplete>
      </manual-entry>

      <manual-entry
          :forced-manual-entry="currentClient.manualHoseConfiguration"
          :initial-manual-entry="formType !== 'add' && filteredConnectionJumpSizes1 != null && filteredConnectionJumpSizes1.filter((obj) => obj.jumpSize === item.connection1JumpSize).length === 0 && item.connection1JumpSize !== null"
          label="Connection 1 size"
          v-model="item.connection1JumpSize"
          @input="onUpdate(item)"
          :errors="errors.connection1JumpSize">
        <v-autocomplete
            label="Connection 1 size"
            v-model="item.connection1JumpSize"
            @input="onUpdate(item)"
            :items="filteredConnectionJumpSizes1"
            :clearable="true"
            item-value="jumpSize"
            item-text="jumpSize"
            append-icon="mdi-chevron-down"
            clear-icon="mdi-close-circle"
            :error="errors.connection1JumpSize != null"
            :error-messages="errors.connection1JumpSize"
        >
        </v-autocomplete>
      </manual-entry>

      <manual-entry
          :forced-manual-entry="currentClient.manualHoseConfiguration"
          :initial-manual-entry="formType !== 'add' && connectionTypes != null && connectionTypes.filter((obj) => obj.name === item.connection1Type).length === 0 && item.connection1Type !== null"
          label="Connection 1 type"
          v-model="item.connection1Type"
          @input="onUpdate(item)"
          :errors="errors.connection1Type">
        <v-autocomplete
            label="Connection 1 type"
            v-model="item.connection1Type"
            @input="onUpdate(item)"
            :items="connectionTypes"
            :clearable="true"
            item-value="name"
            item-text="name"
            append-icon="mdi-chevron-down"
            clear-icon="mdi-close-circle"
            :error="errors.connection1Type != null"
            :error-messages="errors.connection1Type"
        >
        </v-autocomplete>
      </manual-entry>

      <manual-entry
          :forced-manual-entry="currentClient.manualHoseConfiguration"
          :initial-manual-entry="formType !== 'add' && isUUID(item.connection2) === false"
          label="Connection 2"
          v-model="item.connection2"
          @input="onUpdate(item)"
          :errors="errors.connection2">
        <v-autocomplete
            label="Connection 2"
            v-model="item.connection2"
            @input="onUpdate(item)"
            :items="filteredConnections"
            :clearable="true"
            item-value="id"
            item-text="name"
            append-icon="mdi-chevron-down"
            clear-icon="mdi-close-circle"
            :error="errors.connection2 != null"
            :error-messages="errors.connection2"
        >
        </v-autocomplete>
      </manual-entry>

      <manual-entry
          :forced-manual-entry="currentClient.manualHoseConfiguration"
          :initial-manual-entry="formType !== 'add' && filteredConnectionJumpSizes2 != null && filteredConnectionJumpSizes2.filter((obj) => obj.jumpSize === item.connection2JumpSize).length === 0 && item.connection2JumpSize !== null"
          label="Connection 2 size"
          v-model="item.connection2JumpSize"
          @input="onUpdate(item)"
          :errors="errors.connection2JumpSize">
        <v-autocomplete
            label="Connection 2 size"
            v-model="item.connection2JumpSize"
            @input="onUpdate(item)"
            :items="filteredConnectionJumpSizes2"
            :clearable="true"
            item-value="jumpSize"
            item-text="jumpSize"
            append-icon="mdi-chevron-down"
            clear-icon="mdi-close-circle"
            :error="errors.connection2JumpSize != null"
            :error-messages="errors.connection2JumpSize"
        >
        </v-autocomplete>
      </manual-entry>

      <manual-entry
          :forced-manual-entry="currentClient.manualHoseConfiguration"
          :initial-manual-entry="formType !== 'add' && connectionTypes != null && connectionTypes.filter((obj) => obj.name === item.connection2Type).length === 0 && item.connection2Type !== null"
          label="Connection 2 type"
          v-model="item.connection2Type"
          @input="onUpdate(item)"
          :errors="errors.connection2Type">
        <v-autocomplete
            label="Connection 2 type"
            v-model="item.connection2Type"
            @input="onUpdate(item)"
            :items="connectionTypes"
            :clearable="true"
            item-value="name"
            item-text="name"
            append-icon="mdi-chevron-down"
            clear-icon="mdi-close-circle"
            :error="errors.connection2Type != null"
            :error-messages="errors.connection2Type"
        >
        </v-autocomplete>
      </manual-entry>

      <v-text-field
          v-if="item.connection2Type !== 'Straight' && item.connection1Type !== 'Straight'"
          label="Orientation"
          v-model="item.orientation"
          @input="onUpdate(item)"
          type="number"
          suffix="degrees"
          :error="errors.orientation != null"
          :error-messages="errors.orientation"

      />

      <manual-entry
          :forced-manual-entry="currentClient.manualHoseConfiguration"
          :initial-manual-entry="formType !== 'add' && manufacturers != null && manufacturers.filter((obj) => obj.name === item.manufacturer).length === 0 && item.manufacturer !== null"
          label="Hose manufacturer"
          v-model="item.manufacturer"
          @input="onUpdate(item)"
          :errors="errors.manufacturer">

        <v-autocomplete
            v-if="optionalFields.manufacturer !== 1"
            label="Hose manufacturer"
            v-model="item.manufacturer"
            @input="onUpdate(item)"
            :items="manufacturers"
            :clearable="true"
            item-value="name"
            item-text="name"
            append-icon="mdi-chevron-down"
            clear-icon="mdi-close-circle"
            :error="errors.manufacturer != null"
            :error-messages="errors.manufacturer"
        >
        </v-autocomplete>
      </manual-entry>
      <v-checkbox
          v-if="optionalFields.sleeving !== 1"
          label="Has sleeving"
          v-model="item.sleeving"
          @input="onUpdate(item)"
          :error="errors.sleeving != null"
          :error-messages="errors.sleeving"/>

      <v-checkbox
          v-if="optionalFields.testCertificate !== 1"
          label="Has test certificate"
          v-model="item.testCertificate"
          @input="onUpdate(item)"
          :error="errors.testCertificate != null"
          :error-messages="errors.testCertificate"/>

      <v-checkbox
          v-if="optionalFields.conformityCertificate !== 1"
          label="Has certificate of conformity"
          v-model="item.conformityCertificate"
          @input="onUpdate(item)"
          :error="errors.conformityCertificate != null"
          :error-messages="errors.conformityCertificate"/>

      <v-textarea
          class="mt-3"
          label="Notes"
          v-model="item.notes"
          @input="onUpdate(item)"
          :error="errors.notes != null"
          :error-messages="errors.notes"/>


    </template>
  </form-loader>
</template>

<script>
import FormLoader from "../../../components/FormLoader";
import {mapActions, mapGetters} from "vuex";
import ApiSelectBox from "../../../components/ApiSelectBox";
import CrudPage from "../../../components/CrudPage";
import ManualEntry from "@/components/ManualEntry.vue";
import validator from "validator";
import isUUID from "validator/es/lib/isUUID";
import ItemListLoader from "@/components/ItemListLoader.vue";
import {isString} from "lodash";

export default {
  name: "HoseConfigurationAdd",
  components: {ItemListLoader, ManualEntry, CrudPage, ApiSelectBox, FormLoader},
  props: {
    hoseConfigurationId: null,
  },
  data() {
    return {
      configLoaded : false,
      displayPressureBackup: 0,
      currentItem: {
        type: Object,
        default: null,
      }
    }
  },
  async created() {
    await this.getConfig();
    this.configLoaded = true;
  },
  methods: {
    ...mapActions('config', ['getConfig']),
    isBlank(str) {
      return str === undefined || (!str || /^\s*$/.test(str));
    },
    isUUID(value) {
      return value != null && validator.isUUID(value);
    },
    beforeSave(item) {
      if(this.currentItem == null) {
        return;
      }
      //CONVERT IDs TO TEXT VALUES
      item.displayPressure = this.displayPressure;
      item.length = item.length || 0;
      item.boreSize = this.isUUID(this.currentItem.boreSize) ? this.boreSizes.filter((obj) => obj.id === this.currentItem.boreSize)[0]?.name : this.currentItem.boreSize;
      item.type = this.isUUID(this.currentItem.type) ? this.hoseTypes.filter((obj) => obj.id === this.currentItem.type)[0]?.name : this.currentItem.type;
      item.connection1 = this.isUUID(this.currentItem.connection1) ? this.connections.filter((obj) => obj.id === this.currentItem.connection1)[0]?.name : this.currentItem.connection1;
      item.connection2 = this.isUUID(this.currentItem.connection2) ? this.connections.filter((obj) => obj.id === this.currentItem.connection2)[0]?.name : this.currentItem.connection2;
      if (this.isBlank(item.boreSize)) {
        item.boreSize = null;
      }
      if (this.isBlank(item.type)) {
        item.type = null;
      }
      if (this.isBlank(item.connection1)) {
        item.connection1 = null;
      }
      if (this.isBlank(item.connection2)) {
        item.connection2 = null;
      }
      if (this.isBlank(item.connection1JumpSize)) {
        item.connection1JumpSize = null;
      }
      if (this.isBlank(item.connection2JumpSize)) {
        item.connection2JumpSize = null;
      }
      if (this.isBlank(item.connection1Type)) {
        item.connection1Type = null;
      }
      if (this.isBlank(item.connection2Type)) {
        item.connection2Type = null;
      }
      if (this.isBlank(item.manufacturer)) {
        item.manufacturer = null;
      }
      return item;
    },
    itemTransform(item) {
      //CONVERT TEXT TO ID VALUES
      console.log("bores sizes", this.boreSizes);
      console.log("typ sizes", this.type);
      console.log("connection sizes", this.connection1);
      console.log("connection 2 sizes", this.connection2);
      item.boreSize = item.boreSize != null ? (this.boreSizes.filter((obj) => obj.name === item.boreSize)[0]?.id ?? item.boreSize) : null;
      item.type = item.type != null ? (this.hoseTypes.filter((obj) => obj.name === item.type)[0]?.id ?? item.type) : null;
      item.connection1 = item.connection1 != null ? (this.connections.filter((obj) => obj.name === item.connection1)[0]?.id ?? item.connection1) : null;
      item.connection2 = item.connection2 != null ? (this.connections.filter((obj) => obj.name === item.connection2)[0]?.id ?? item.connection2) : null;

      return item;
    },
    onItemUpdated(item) {
      console.log("ITEM HAS BEEN UPDATED", item);
      this.currentItem = item;
      var list = this.sizeMappings.filter(
          (sm) => sm.configHoseTypeId === this.currentItem.type && sm.configBoreSizeId === this.currentItem.boreSize
      );

      if (list.length > 0) {
        const calculatedPressure = list[0].displayPressure;

        // If displayPressureBackup differs from calculated pressure, it has been overridden
        if (this.displayPressureBackup && this.displayPressureBackup !== calculatedPressure) {
          return; // Return the overridden value
        }
      }
      this.displayPressureBackup = item.displayPressure;
    },
  },
  computed: {
    ...mapGetters('users', ['item']),
    ...mapGetters('auth', ['user', 'clientAdmin', 'distributor', 'organisation', 'optionalFields']),
    ...mapGetters('config', ['sizeMappings', 'hoseTypes', 'boreSizes', 'manufacturers', 'connectionTypes', 'connections', 'connectionsJumpSizes', 'loading', 'loadingError']),
    ...mapGetters('clients', {
      currentClient: 'item'
    }),
    formType() {
      if (this.$router.currentRoute.name === "HoseConfigurationDelete") return 'delete';
      if (this.$router.currentRoute.name === "HoseConfigurationView") return 'view';
      return this.hoseConfigurationId != null ? "edit" : "add";
    },
    formTitle() {
      if (this.formType === "delete") {
        return "Delete configuration";
      } else if (this.formType === "view") {
        return "View configuration";
      } else if (this.formType === "add") {
        return "Create configuration";
      } else if (this.formType === "edit") {
        return "Edit configuration";
      }
    },
    filteredHoseTypes() {
      return this.hoseTypes;
    },
    filteredBoreSizes() {
      if(this.currentItem == null) {
        return [];
      }
      if (this.currentItem.type && isString(this.currentItem) && isUUID(this.currentItem.type)) {
        console.log("HOSE TYPE SELECTED IS ", this.currentItem.type);
        console.log("SIZE MAPPINGS ARE ", this.sizeMappings);
        var availableBoreSizes = this.sizeMappings.filter((sm) => sm.configHoseTypeId === this.currentItem.type).map((obj) => {
          return obj.configBoreSizeId
        });
        console.log("BORES SIZE IDS ARE ", availableBoreSizes);
        return this.boreSizes.filter((obj) => {
          return availableBoreSizes.indexOf(obj.id) !== -1
        })
      }
      return this.boreSizes;
    },
    displayPressure : {
      get() {
        if (this.currentItem.type && this.currentItem.boreSize && isUUID(this.currentItem.type) && isUUID(this.currentItem.boreSize)) {
          var list = this.sizeMappings.filter(
              (sm) => sm.configHoseTypeId === this.currentItem.type && sm.configBoreSizeId === this.currentItem.boreSize
          );

          if (list.length > 0) {
            const calculatedPressure = list[0].displayPressure;

            // If displayPressureBackup differs from calculated pressure, it has been overridden
            if (this.displayPressureBackup && this.displayPressureBackup !== calculatedPressure) {
              return this.displayPressureBackup; // Return the overridden value
            }

            return calculatedPressure; // Return the automatic value
          }
        }

        // If no valid type or boreSize, return the backup (which could be manually set)
        return this.displayPressureBackup;
      },
      set(value) {
        // Set the backup to the new value, treating it as a manual override
        this.displayPressureBackup = value;
      }
    },
    filteredConnections() {
      if(this.currentItem == null) {
        return [];
      }
      console.log("AVAILABLE JUMP SIZES", this.connectionsJumpSizes.length);
      if (this.currentItem.boreSize && isUUID(this.currentItem.boreSize)) {
        console.log("HAS BODRE SIZE OF", this.currentItem.boreSize)
        var availableConnections = this.connectionsJumpSizes.filter((js) => js.configBoreSizeId === this.currentItem.boreSize).map((obj) => {
          return obj.configConnectionId
        });
        return this.connections.filter((obj) => {
          return availableConnections.indexOf(obj.id) !== -1
        })
      }
      return this.connections;
    },
    filteredConnectionJumpSizes1() {
      if(this.currentItem == null) {
        return [];
      }
      if (this.currentItem.connection1 && this.currentItem.boreSize && isUUID(this.currentItem.connection1) && isUUID(this.currentItem.boreSize)) {
        return this.connectionsJumpSizes.filter((obj) => {
          return obj.configBoreSizeId === this.currentItem.boreSize && obj.configConnectionId === this.currentItem.connection1
        });
      }
      return this.connectionsJumpSizes;
    },
    filteredConnectionJumpSizes2() {
      if(this.currentItem == null) {
        return [];
      }
      if (this.currentItem.connection2 && this.currentItem.boreSize && isUUID(this.currentItem.connection2) && isUUID(this.currentItem.boreSize)) {
        return this.connectionsJumpSizes.filter((obj) => {
          return obj.configBoreSizeId === this.currentItem.boreSize && obj.configConnectionId === this.currentItem.connection2
        });
      }
      return this.connectionsJumpSizes;
    },
  }
}
</script>

<style scoped>

</style>
