<template>
    <div>
        <filter-field v-for="(filter,index) in filters"
                      :key="filter.id" v-model="filters[index]"/>
    </div>
</template>

<script>
    import FilterField from "./FilterField";

    export default {
        name: "FilterMenu",
        components: {FilterField},
        props: {
            value: {
                type: Array,
                default() {
                    return [];
                }
            }
        },
        data() {
            return {
                filters: this.value
            }
        },
        watch: {
            value: {
                deep: true,
                handler(newVal, oldVal) {
                    if(newVal.length !== oldVal.length) {
                        this.filters = newVal;
                    }
                }
            },
            filters: {
                deep: true,
                handler(newVal, oldVal) {
                    console.log("Filters updated", newVal);
                    this.$emit('input', newVal);
                }
            }
        },
        computed: {},
        methods: {}

    }
</script>
