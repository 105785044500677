<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-menu
            :offset-y="true"
            :close-on-content-click="true"
    >
        <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                            color="light"
                            elevation="0"
                            class="px-0"
                            small
                            v-bind="attrs"
                            tooltip="Quick view"
                            v-on="{ ...tooltip, ...menu }"
                    >
                        <v-icon color="primary">mdi-gesture</v-icon>
                        <v-icon size="13">mdi-chevron-down</v-icon>
                    </v-btn>
                </template>
                <span>Quick view</span>
            </v-tooltip>
        </template>
        <v-card class="pa-3">
            <hose-configuration-view :id="id" :hose-id="hoseId"></hose-configuration-view>
        </v-card>
    </v-menu>
</template>

<script>
    import HoseConfigurationView from "./HoseConfigurationView";

    export default {
        name: "HoseConfigurationDropdown",
        components: {HoseConfigurationView},
        props: {
            title: null,
            subtitle: null,
            hoseId: null,
            id: null
        }
    }
</script>

<style scoped>

</style>
