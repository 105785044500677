import {baseActions, baseGetters, baseMutations, baseState} from "./baseStore";
import {endpoints, httpRequest} from "@/store/network/hitag.client";

function state(){
    return {
        ...baseState(),
    }
}

function getters(){
    return {
        ...baseGetters(),
    }
}

function mutations(){
    return {
        ...baseMutations(),
    }
}

function actions(){
    return {
        ...baseActions('manufacturers'),
    }
}

function manufacturersModule()
{
    return {
        namespaced: true,
        state: state(),
        getters: getters(),
        mutations: mutations(),
        actions: actions()
    }
}
export default manufacturersModule;
