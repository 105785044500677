<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <crud-page icon="mdi-account-group-outline" :actions="actions" title="Users">
    <paginated-result-loader
        :enable-exports="true"
        namespace="users">
      <template v-slot:default="props">
        <UserList :items="props.items"/>
      </template>
      <template v-slot:header>
        <v-menu
            :right="true"
            v-if="pendingItems.length > 0"
            :offset-y="true"
            :close-on-content-click="true"
        >
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                    :loading="pendingLoading"
                    color="primary"
                    elevation="0"
                    small
                    v-bind="attrs"
                    tooltip="View pending users"
                    v-on="{ ...tooltip, ...menu }"
                >
                  <v-icon color="white" size="16">mdi-account-clock</v-icon>
                  <span class="mr-2 ml-2">Pending invites ({{ pendingItems.length }})</span>
                  <v-icon size="13">mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <span>View pending invites</span>
            </v-tooltip>
          </template>
          <v-card :loading="pendingLoading">
            <pending-list :items="pendingItems"></pending-list>
          </v-card>
        </v-menu>
      </template>
    </paginated-result-loader>
  </crud-page>
</template>

<script>

import CrudPage from "../../../components/CrudPage";
import {mapActions, mapGetters} from "vuex";
import PaginatedResultLoader from "../../../components/PaginatedResultLoader";
import UserList from "../../../components/users/UserList";
import PendingList from "../../../components/users/PendingList";
import emitter from "@/utils/emitter";

export default {
  name: 'Users',
  components: {
    PendingList,
    UserList,
    PaginatedResultLoader,
    CrudPage
  },
  created() {
    this.getPendingItems();
    emitter.on('userUpdated', this.userUpdated);
  },
  beforeDestroy() {
    emitter.off('userUpdated');
  },
  methods: {
    ...mapActions('users', ['getPendingItems', 'items']),
    async userUpdated() {
      await this.getPendingItems();
      await this.$store.dispatch('users/getItems', {});
      await this.$router.replace({name: 'Users'}).catch(() => {
      });
    }
  },
  watch: {},
  computed: {
    ...mapGetters('auth', ['clientAdmin', 'user']),
    ...mapGetters('users', ['pendingItems', 'pendingLoading', 'pendingError']),
    actions() {
      var list = [];
      list.push({
        icon: 'mdi-plus-circle-outline',
        title: 'Create user',
        route: {name: 'UserAdd'},
        variant: 'success'
      });
      return list;
    }
  }
}
</script>
