<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-list two-line class="pa-0">
        <template v-for="(item, index) in items">
            <v-list-item :key="item.id">
                <template v-slot:default="{ active }">
                    <v-list-item-content>

                        <v-list-item-title class="font-weight-bold mb-2">
                            {{ quoteTitle(item) }}

                        </v-list-item-title>
                        <v-list-item-subtitle
                                class="text--primary text-sm-left mb-2">
                            Requested by {{ item.requesterUser.fullName }} on {{ $options.filters.formatDate(item.createdAt) }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                                v-if="item.completedAt"
                                class="text--primary text-sm-left mb-2">
                            <v-chip color="success" >
                                Completed on {{ $options.filters.formatDate(item.completedAt) }}
                            </v-chip>
                        </v-list-item-subtitle>

                        <v-list-item-subtitle v-text="">

                            <span class="mr-3" v-if="item.hose.organisationZone">
                                <site-dropdown :title="item.hose.organisationZone.name" :id="item.hose.organisationZoneId"/>
                            </span>

                            <hose-configuration-dropdown
                                    title="View hose"
                                    class="mr-3"
                                    :hose-id="item.hoseId"></hose-configuration-dropdown>

                            <span class="mr-3 ml-3">
                                #{{ item.hose.id }}
                            </span>

                            <span v-if="item.hose.name" class="mr-3">
                                Ref: {{ item.hose.name }}
                            </span>

                        </v-list-item-subtitle>


                    </v-list-item-content>
                    <ActionDropdown :items="getDropdown(item)"/>

                </template>

            </v-list-item>
            <v-progress-linear v-if="item.id === completeLoading" :indeterminate="true"></v-progress-linear>
            <v-divider
                    v-if="index < items.length - 1"
                    :key="index"
            ></v-divider>
        </template>
    </v-list>
</template>
<script>
    import {mapActions, mapGetters} from "vuex";
    import ActionDropdown from "../ActionDropdown";
    import HoseConfigurationView from "../hose-configurations/HoseConfigurationView";
    import HoseConfigurationDropdown from "../hose-configurations/HoseConfigurationDropdown";
    import SiteDropdown from "../sites/SiteDropdown";

    export default {
        name: 'QuoteList',
        components: {SiteDropdown, HoseConfigurationDropdown, HoseConfigurationView, ActionDropdown},
        props: {
            items : {
                type : Array,
                default() {
                    return [];
                }
            }
        },
        methods : {
            ...mapActions('quotes',['completeQuote']),
            getDropdown(item) {
                var data = [
                    {
                        label : 'View quotation',
                        icon : 'mdi-clipboard-check-outline',
                        route : {name : 'QuoteView' , params : {quoteId : item.id}}
                    },
                    {
                        label : 'View hose',
                        icon : 'mdi-gesture',
                        route : {name : 'HoseDetail' , params : {hoseId : item.hoseId}}
                    }
                ];
                if(!item.completedAt && !this.clientAdmin) {
                    data.push({
                        label : 'Mark as completed',
                        icon : 'mdi-check',
                        click : () => { this.completeQuote(item.id) }
                    });
                }
                return data;
            },
            quoteTitle(item) {
                console.log("ITEM IS ",item);
                if(this.distributor) {
                    if(item.requesterOrganisationId === this.organisation.id) {
                        return "Your company offered a quote to "+item.receiverOrganisation.name;
                    } else {
                        return item.requesterOrganisation.name +" requested a quote";
                    }
                } else if(this.customer) {
                    if(item.requesterOrganisationId === this.organisation.id) {
                        return "Your company requested a quote from "+item.receiverOrganisation.name;
                    } else {
                        return item.requesterOrganisation.name + " offered to provide a quote";
                    }
                } else {
                    return item.requesterOrganisation.name + " sent request to " + item.receiverOrganisation.name;
                }
            }
        },
        computed: {
            ...mapGetters('auth', ['clientAdmin','user','organisation','distributor','customer']),
            ...mapGetters('quotes', ['completeLoading']),
        },
    }
</script>
