<template>
  <div>
    <h1 v-if="!hideTitle" class="primary--text">Privacy Policy</h1>
    <div v-html="currentClient.privacyPolicy"></div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "PrivacyPolicy",
  props: {
    hideTitle: false
  },
  computed: {
    ...mapGetters('clients', {currentClient: 'item'})
  }
}
</script>

<style scoped lang="scss">
h2, h3 {
  margin-top: 10px;
}

ul {
  margin-bottom: 16px;
}
</style>
