<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <form-loader
      :on-save-listeners="['userUpdated']"
      :form-type="formType"
      class="px-4"
      :title="userId ? 'Edit user' : 'Add user'"
      :item-transform="itemTransform"
      :before-save="beforeSave"
      :id="userId"
      v-on:itemUpdated="itemUpdated"
      namespace="users">

    <template #default="{item,errors,onUpdate}">

      <v-text-field
          :disabled="formType === 'edit'"
          label="First name"
          v-model="item.firstName"
          @input="onUpdate(item)"
          :error="errors.firstName != null"
          :error-messages="errors.firstName"
      />

      <v-text-field label="Last name"
                    :disabled="formType === 'edit'"
                    v-model="item.lastName"
                    @input="onUpdate(item)"
                    :error="errors.lastName != null"
                    :error-messages="errors.lastName"/>

      <v-text-field
          :disabled="formType === 'edit'"
          label="Email"
          v-model="item.email"
          @input="onUpdate(item)"
          :error="errors.email != null"
          :error-messages="errors.email"/>


      <v-checkbox
          v-if="clientAdmin"
          label="Make this user a client admin"
          v-model="item.clientAdmin"
          @input="onUpdate(item)"
          :error="errors.clientAdmin != null"
          :error-messages="errors.clientAdmin"/>

      <template v-if="clientAdmin && !item.clientAdmin">
        <item-list-loader

            namespace="organisations"
            :limit="1000"
        >
          <template v-slot:main="orgSlot">
            <v-select
                class="mb-4"
                :hide-details="true"
                v-model="selectedOrg"
                :items="orgSlot.items"
                @input="(newOrg) => { addOrg(newOrg,item,onUpdate) }"
                item-text="name"
                item-value="id"
                :return-object="true"
                label="Select organisation"
            ></v-select>
          </template>
        </item-list-loader>

        <div v-if="!item.clientAdmin" class="d-flex rounded light align-center mt-2 pa-2 mb-2"
             v-for="(org,orgKey) in organisations">
          <div class="flex-fill">
            {{ org.label }}
          </div>
          <div class="flex-wrap">
            <v-select
                small
                label="Role"
                item-value="value"
                item-text="label"
                :items="roleTypes"
                v-model="org.role"
            />
          </div>
          <div class="flex-wrap">
            <v-checkbox
                label="Quotes"
                v-model="org.receiveQuotes"
                @input="onUpdate(item)"
                :error="errors.receiveQuotes != null"
                :error-messages="errors.receiveQuotes"/>
          </div>
          <div class="flex-wrap ml-2">
            <v-icon color="primary" @click="() => { removeOrg(org.organisationId,item,onUpdate) }">
              mdi-delete
            </v-icon>
          </div>
        </div>
      </template>
      <template v-else-if="!item.clientAdmin">
        <v-checkbox
            label="This user should receive quote request emails"
            v-model="item.receiveQuotes"
            @input="onUpdate(item)"
            :error="errors.receiveQuotes != null"
            :error-messages="errors.receiveQuotes"/>
        <v-select

            label="Role"
            item-value="value"
            item-text="label"
            :items="roleTypes"
            @input="onUpdate(item)"
            v-model="item.role"
        />

      </template>


      <user-roles-dropdown v-if="!item.clientAdmin"></user-roles-dropdown>

      <v-alert class="mt-4" v-if="limitedAccess" type="error">
        You do not have permission to edit this user
      </v-alert>

    </template>
  </form-loader>
</template>

<script>
import FormLoader from "../../../components/FormLoader";
import ApiSelectBox from "../../../components/ApiSelectBox";
import {mapActions, mapGetters} from "vuex";
import UserRoles from "../../../components/users/UserRoles";
import UserRolesDropdown from "../../../components/users/UserRolesDropdown";
import ItemListLoader from "@/components/ItemListLoader.vue";


export default {
  name: "UserAdd",
  props: {
    userId: null
  },
  computed: {
    ...mapGetters('users', ['item']),
    ...mapGetters('auth', ['user', 'clientAdmin', 'organisation', 'distributor', 'customer']),
    formType() {
      return this.userId != null ? (this.limitedAccess ? "view" : "edit") : "add";
    },
    limitedAccess() {
      var transformed = this.itemTransform(this.item);
      if(!this.clientAdmin && this.organisation.role === 1 &&  transformed.role === 2) {
        return true;
      }
      return false;
    },
    roleTypes() {
      if (this.clientAdmin || this.organisation.role > 1) {
        var data = [
          {
            label: 'User',
            value: 0
          },
          {
            label: 'Manager',
            value: 1
          },
          {
            label: 'Admin',
            value: 2
          }
        ];
        if (this.formType === 'edit') {
          data.push(
              {
                label: 'Revoke this user\'s access',
                value: 3
              });
        }
        return data;
      }
      var data = [
        {
          label: 'User',
          value: 0
        },
        {
          label: 'Manager',
          value: 1
        }
      ];
      if (this.formType === 'edit') {
        data.push(
            {
              label: 'Revoke this user\'s access',
              value: 3
            });
      }
      return data;
    }
  },
  created() {
    if (!this.clientAdmin && !this.distributor) {
      this.organisations = [{label: "Access level", organisationId: this.organisation.id, role: 0}];
    }
  },
  watch: {
    item(val) {
      if (val.id === this.user.id) {
        this.me();
      }
    }
  },
  data() {
    return {
      searchResetId: null,
      selectedOrg: null,
      organisations: []
    }
  },
  components: {ItemListLoader, UserRolesDropdown, UserRoles, ApiSelectBox, FormLoader},
  methods: {
    ...mapActions('auth', ['me']),
    itemTransform(item) {

      item.organisationIds = item.organisations == null ? [] : item.organisations.map((org) => {
        return {organisationId: org.id, role: org.role, label: org.name, receiveQuotes: org.receiveQuotes};
      });
      if (!this.clientAdmin) {
        var currentRole = item.organisationIds.filter((obj) => obj.organisationId === this.organisation.id);
        if (currentRole.length > 0) {
          item.role = currentRole[0].role;
          item.receiveQuotes = currentRole[0].receiveQuotes;
        }
      }
      return item;
    },
    beforeSave(item) {
      if (!this.clientAdmin) {
        if (item.role === 3) {
          item.organisationIds = [];
        } else {
          item.organisationIds = [{
            label: "Access level",
            organisationId: this.organisation.id,
            role: item.role,
            receiveQuotes: item.receiveQuotes
          }];
        }
      }
      return item;
    },
    addOrg(org, item, onUpdate) {
      console.log("ORG IS", org);
      if (org === undefined) return;
      console.log("ITEM IS", item);
      if (this.organisations === undefined) this.organisations = [];
      if (this.organisations.filter((obj) => {
        return obj.organisationId === org.id
      }).length === 0) {
        this.organisations.push(
            {organisationId: org.id, role: 0, label: org.name}
        );
        item.organisationIds = this.organisations;
        onUpdate(item);
      }
      this.searchResetId = Math.random();
      this.selectedOrg = null;
    },
    removeOrg(id, item, onUpdate) {
      console.log("REMOVING", id);
      item.organisationIds = item.organisationIds.filter((obj) => {
        return obj.organisationId !== id
      });
      console.log("ITEM IS ", item.organisationIds);
      this.selectedOrg = null;
      this.searchResetId = Math.random();
      onUpdate(item);
    },
    itemUpdated(item) {
      if (!this.customer) {
        console.log("ITEM UPDATED HERE", item);
        if (item.organisationIds !== undefined) ;
        this.organisations = item.organisationIds;
      }
    }
  }
}
</script>

<style scoped>

</style>
