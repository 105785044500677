<template>
  <v-app class="theme--light">
    <v-main class="py-5">
      <div class="d-flex flex-column justify-center fill-height">
        <v-row align-content="center" class="justify-center">
          <div :class="wrapperClasses">
            <router-link to="/" tag="span" class="d-inline-block" style="cursor: pointer">
              <v-img
                  alt="Hi Tag - hose intelligence"
                  class="mb-3"
                  contain
                  width="125"
                  :src="logoUrl"
              />
            </router-link>
            <h1 class="mb-3 " v-if="title">{{ title }}</h1>

            <v-card v-if="boxed">
              <slot></slot>
            </v-card>
            <template v-else>
              <slot></slot>
            </template>
          </div>
        </v-row>
      </div>
    </v-main>
    <alert-display></alert-display>
  </v-app>
</template>

<script>
import AlertDisplay from "../components/AlertDisplay";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "CenteredLayout",
  components: {AlertDisplay},
  props: {
    title: null,
    boxed: {
      type: Boolean,
      default: true
    },
    large: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('clients', ['item']),
    logoUrl() {
      return require('@/assets/'+ this.item.theme +'/logo.png');
    },
    wrapperClasses() {
      return this.large ? 'col-11 col-lg-6' : 'col-11 col-lg-4 col-md-6';
    }
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss">
h1 {
  color: var(--v-text-base) !important;
  font-size: 28px;
}
</style>
