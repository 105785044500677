<template>
  <div>
    <h1 v-if="!hideTitle" class="primary--text">{{ currentClient.name }} WEBSITE AND APP – LICENCE AND TERMS OF USE</h1>
    <div v-html="currentClient.termsAndConditions"></div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "TermsOfUse",
  props: {
    hideTitle: false
  },
  computed: {
    ...mapGetters('clients', {currentClient: 'item'})
  }
}
</script>

<style scoped lang="scss">
h2 {
  margin-top: 10px;
}
</style>
