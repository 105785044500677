<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <centered-layout :boxed="false" title="Select an organisation to continue with">
        <v-card>
            <v-list two-line class="pa-0">

                <template v-for="(item, index) in user.organisations">
                    <v-list-item :to="{ name : 'Portal', params: {currentOrganisationId : item.id}}" :key="item.id">
                        <template v-slot:default="{ active }">
                            <v-list-item-avatar class="primary white--text font-weight-bold">
                                {{ item.name.charAt(0) + item.name.charAt(1) }}
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="d-flex justify-space-between align-center">
                                    {{ item.name }}
                                    <v-chip
                                            class="flex-wrap"
                                            :color="$options.filters.roleToColor(item.role)"
                                            x-small
                                            outlined
                                            pill
                                    >{{ $options.filters.roleToString(item.role) }}
                                    </v-chip>
                                </v-list-item-title>

                                <v-list-item-subtitle
                                        class="text--primary"
                                >
                                    Account code: {{ item.accountCode }}
                                </v-list-item-subtitle>

                                <v-list-item-subtitle v-text="">
                                    {{ item.isDistributor ? "Distributor account" : "Customer account" }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                    <v-divider
                            v-if="index < user.organisations.length - 1"
                            :key="index"
                    ></v-divider>
                </template>
            </v-list>
        </v-card>
        <div class="mt-3 text-center">
            <v-btn v-if="!systemAdmin" :to="{name: 'OrganisationCreate'}" class="" small depressed>
                <v-icon class="mr-1" size="14">mdi-plus</v-icon>
                Create new organisation
            </v-btn>
            <v-btn :to="{name: 'Logout'}" class="" small depressed>
                <v-icon class="mr-1" size="14">mdi-logout</v-icon>
                Logout
            </v-btn>
        </div>
    </centered-layout>
</template>

<script>
    import {mapActions, mapGetters, mapState} from "vuex";
    import CenteredLayout from "../layouts/CenteredLayout";
    import GlobalErrorInline from "../components/GlobalErrorInline";

    export default {
        name: 'OrganisationSelect',
        data: () => ({
            email: null,
            password: null
        }),
        computed: {
            ...mapGetters('auth', ['loading', 'user', 'multiOrg', 'clientAdmin', 'loginError','systemAdmin']),
        },
        watch: {
            user(newVal, oldVal) {
                if (this.clientAdmin) {
                    this.$router.push({name: 'Portal', params: {currentOrganisationId: 'admin'}});
                } else if (this.multiOrg) {
                    this.$router.push({name: 'OrganisationSelect', params: {currentOrganisationId: 'admin'}}).catch(() => {
                    });
                } else if (newVal.organisations.length > 0) {
                    this.$router.push({
                        name: 'OrganisationSelect',
                        params: {currentOrganisationId: newVal.organisations[0].OrganisationId}
                    });
                }
            }
        },
        methods: {
            ...mapActions('auth', ['login'])
        },
        components: {GlobalErrorInline, CenteredLayout},
    }
</script>
