import emitter from "@/utils/emitter";

export default function listenersMixin() {
    return {
        props: {
            listeners: {
                type: Array,
                default
                    ()
                {
                    return [];
                }
            }
        },
        created() {
            this.listeners.forEach(listenerKey => {
                emitter.on(listenerKey, () => {
                    this.getItems();
                });
            })
        },
        beforeDestroy() {
            this.listeners.forEach(listenerKey => {
                emitter.off(listenerKey);
            });
        }
    }
}
