import {httpRequest, endpoints} from "../network/hitag.client";

const currentUser = () => {
    try {
        return JSON.parse(localStorage.getItem('user'));
    } catch (e) {
        return null;
    }
}


const authModule = {
    namespaced: true,
    state: () => ({
        user: currentUser(),
        unverifiedUser : null, // USED FOR REGISTRATION PROCESS
        tempUser: null,
        createdUser: null,
        loading: false,
        loginError: {},
        registerError: {},
        validationError: {},
        customer: false,
        distributor: false,
        organisation: {},
        currentOrganisationId : null,
    }),
    getters: {
        user: state => state.user,
        createdUser: state => state.createdUser,
        isUserAdmin: (state, getters) => {
            return getters.clientAdmin || (getters.organisation != null && getters.organisation.role === 1 || getters.organisation.role === 2)
        },
        isAdmin: (state, getters) => {
            return getters.clientAdmin || (getters.organisation != null && getters.organisation.role === 2)
        },
        unverifiedUser: state => state.unverifiedUser,
        tempUser: state => state.tempUser,
        loading: state => state.loading,
        loginError: state => state.loginError,
        registerError: state => state.registerError,
        validationError: state => state.validationError,
        currentOrganisationId: state => state.currentOrganisationId,
        authenticated: (state, getters) => {
            return getters.user != null && getters.user.id
        },
        clientAdmin: (state, getters, rootState, rootGetters) => {
            return getters.authenticated && getters.user.clientAdmin && rootGetters['clients/item'].id === getters.user.clientId
        },
        systemAdmin: (state, getters) => {
            return getters.authenticated && getters.user.systemAdmin
        },
        multiOrg : (state,getters) => {
            return getters.authenticated && getters.user.organisations && getters.user.organisations.length > 1;
        },
        distributor: (state, getters) => {
            return state.distributor;
        },
        customer: (state, getters) => {
            return state.customer;
        },
        organisation: (state, getters) => {
            return state.organisation;
        },
        optionalFields: (state,getters) => {
            var response = {
                manufacturer : 0,
                manufactureDate : 0,
                sleeving : 0,
                testCertificate : 0,
                conformityCertificate : 0,
            };
            if(getters.organisation != null && getters.organisation.fields != null) {
                for (const [key, value] of Object.entries(getters.organisation.fields)) {
                    response[key] = value;
                }
            }
            return response;
        }
    },
    mutations: {
        loading(state, data) {
            state.loading = data;
        },
        loginError(state, data) {
            state.loginError = data;
        },
        registerError(state, data) {
            state.registerError = data;
        },
        validationError(state, data) {
            state.validationError = data;
        },
        updateUser(state, data) {
            state.user = data;
            if(state.user && state.user.id) {
                var org = state.user.organisations && state.currentOrganisationId != null ? state.user.organisations.find(org => org.id === state.currentOrganisationId) : null;
                state.organisation = org;
                if(org && org.isDistributor) {
                    state.customer = false;
                    state.distributor = true;
                } else if(org && !org.isDistributor) {
                    state.customer = true;
                    state.distributor = false;
                } else {
                    state.customer = false;
                    state.distributor = false;
                }
            }
        },
        updateTempUser(state, data) {
            state.tempUser = data;
        },
        updateUnverifiedUser(state, data) {
            state.unverifiedUser = data;
        },
        updateCreatedUser(state, data) {
            state.createdUser = data;
        },
        logout(state) {
            localStorage.removeItem('impersonatingAccessToken');
            localStorage.removeItem('impersonatingRefreshToken');
            localStorage.removeItem('impersonatingUser');
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('user');
            state.currentOrganisationId = null;
            this.commit("auth/updateUser",{});
        },
        currentOrganisation(state,organisationId) {
            state.currentOrganisationId = organisationId;
            this.commit("auth/updateUser",state.user);
        }
    },
    actions: {
        async login({commit, dispatch}, data) {
            commit('loginError', {});
            commit('loading', true);
            try {
                let loginResponse = await httpRequest(endpoints.auth.login, data);
                await localStorage.setItem('accessToken', loginResponse.data.accessToken);
                await localStorage.setItem('refreshToken', loginResponse.data.refreshToken);
                return await dispatch('me');
            } catch (e) {
                commit('loginError', e.response.data);
            } finally {
                commit('loading', false);
            }
        },
        async register({commit, dispatch}, data) {
            commit('registerError', {});
            commit('loading', true);
            try {
                let response = await httpRequest(endpoints.auth.register, data);
                commit('updateUnverifiedUser',response.data);
            } catch (e) {
                commit('registerError', e.response.data);
            } finally {
                commit('loading', false);
            }
        },
        async forgotPassword({commit, dispatch}, data) {
            commit('loginError', {});
            commit('loading', true);
            try {
                return await httpRequest(endpoints.auth.forgotPassword, data);
            } catch (e) {
                commit('loginError', e.response.data);
            } finally {
                commit('loading', false);
            }
        },
        async validateToken({commit, dispatch}, token) {
            commit('validationError', {});
            commit('loading', true);
            try {
                let response = await httpRequest(endpoints.auth.validateToken(token), null);
                console.log("RESPONSE IS",response);
                commit('updateTempUser',response.data);
            } catch (e) {
                console.log(e);
                commit('validationError', e.response.data);
            } finally {
                commit('loading', false);
            }
        },
        async createPassword({commit, dispatch}, data) {
            commit('validationError', {});
            commit('loading', true);
            try {
                let response = await httpRequest(endpoints.auth.createPassword, data);
                commit('updateCreatedUser',response.data);
            } catch (e) {
                console.log(e);
                commit('validationError', e.response.data);
            } finally {
                commit('loading', false);
            }
        },
        async me(context) {
            let res = await httpRequest(endpoints.auth.me,null);
            await localStorage.setItem('user',JSON.stringify(res.data));
            context.commit('updateUser',res.data);
            console.log("UPDATED USER",res.data);
        },
        setOrganisation(context,organisationId) {
            context.commit('currentOrganisation',organisationId);
        },
        logout(context) {
            context.commit('logout');
        }
    }
};
export default authModule;
