<template>
    <portal-layout>
        <router-view></router-view>
        <alert-display></alert-display>
    </portal-layout>
</template>

<script>
    import PortalLayout from "../../layouts/PortalLayout";
    import AlertDisplay from "../../components/AlertDisplay";
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: 'PortalIndex',
        components: {
            AlertDisplay,
            PortalLayout,
        },
        created() {
            this.$store.dispatch('config/getConfig')
        },
        computed : {
            ...mapGetters('auth',['authenticated'])
        },
        watch : {
            authenticated(newVal,oldVal) {
                console.log("CHANGED AUTHENTICATION");
                if(!newVal) {
                    this.$router.replace({name : 'Home'}).then(() => {
                        this.setUserMessage("You have been logged out");
                    });
                }
            }
        },
        methods : {
            ...mapActions('alerts', ['setUserMessage'])
        }
    }
</script>
