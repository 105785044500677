<template>
  <v-list
      nav
      dense
  >
    <v-list-item-group
        active-class="active"
    >

      <v-list-item
          :exact="true"
          :class="{'active' : $route.matched.filter(r => r.name === item.route).length > 0 }"
          v-for="(item, i) in topMenu"
          :key="i"
          :to="{name : item.route, query: {clear : item.clear}}"
      >
        <v-list-item-icon class="mr-2">
          <v-icon v-text="item.icon"></v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="font-weight-bold" v-text="item.text"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>


  </v-list>
</template>

<style scoped lang="scss">
.active {
  background: var(--v-primary-base);
  color: var(--v-white-base);
  * {
    color: var(--v-white-base);
  }
}
</style>

<script>
import {mapGetters} from "vuex";

export default {
  name: "DashboardSideMenu",
  data: () => ({}),
  computed: {
    ...mapGetters('auth', ['user', 'clientAdmin', 'distributor', 'customer', 'isUserAdmin', 'isAdmin', 'systemAdmin','organisation']),
    topMenu() {
      var data = [
        {icon: 'mdi-view-dashboard-outline', text: 'Dashboard', route: 'Portal'},
      ];
      if (this.systemAdmin) {
        data.push({icon: 'mdi-database-cog', text: 'Manage clients', route: 'Clients'});
        data.push({icon: 'mdi-tag-multiple-outline', text: 'Tag management', route: 'TagManagement', clear: true});
      }
      if (this.clientAdmin) {

        data.push({icon: 'mdi-office-building-outline', text: 'Organisations', route: 'Organisations', clear: true});
        data.push({icon: 'mdi-account-group-outline', text: 'Users', route: 'Users', clear: true});
        data.push({icon: 'mdi-cog-outline', text: 'Configuration library', route: 'HoseConfigurations', clear: true});
        data.push({icon: 'mdi-gesture', text: 'Tagged hoses', route: 'Hoses', clear: true});
        data.push({icon: 'mdi-clipboard-list-outline', text: 'Inspections', route: 'Inspections', clear: true});
        data.push({icon: 'mdi-clipboard-check-outline', text: 'Quote requests', route: 'Quotes', clear: true});
      } else if (this.distributor) {
        data.push({icon: 'mdi-office-building-outline', text: 'Customers', route: 'Organisations', clear: true});
        if (this.isUserAdmin) {
          data.push({icon: 'mdi-account-group-outline', text: 'Users', route: 'Users', clear: true});
        }
        data.push({icon: 'mdi-cog-outline', text: 'Configuration library', route: 'HoseConfigurations', clear: true});
        data.push({icon: 'mdi-gesture', text: 'Tagged hoses', route: 'Hoses', clear: true});
        data.push({icon: 'mdi-clipboard-list-outline', text: 'Inspections', route: 'Inspections', clear: true});
        data.push({icon: 'mdi-clipboard-check-outline', text: 'Quote requests', route: 'Quotes', clear: true});
      } else if (this.customer) {
        data.push({icon: 'mdi-map-legend', text: 'Sites & zones', route: 'SitesAndZones', clear: true});
        if (this.isUserAdmin) {
          data.push({icon: 'mdi-account-group-outline', text: 'Users', route: 'Users', clear: true});
        }
        if(this.clientAdmin || this.distributor || (this.customer && this.organisation.configurationsVisible)) {
          data.push({icon: 'mdi-cog-outline', text: 'Configuration library', route: 'HoseConfigurations', clear: true});
        }
        data.push({icon: 'mdi-gesture', text: 'Tagged hoses', route: 'Hoses', clear: true});
        data.push({icon: 'mdi-clipboard-list-outline', text: 'Inspections', route: 'Inspections', clear: true});
        data.push({icon: 'mdi-clipboard-check-outline', text: 'Quote requests', route: 'Quotes', clear: true});
      }

      return data;
    },
  }
}
</script>

<style scoped>

</style>
