<template>
    <v-simple-table>
        <template>
            <thead>
            <tr>
                <th>
                    {{ clientAdmin ? 'Distributor/Customer' : (distributor ? 'Customer' : 'Distributor')}}
                </th>
                <th class="text-left">
                    Site & Zone
                </th>
                <th class="text-left">
                    Status
                </th>
                <th v-if="full" class="text-left">
                    Config
                </th>
                <th v-if="full" class="text-left">
                    Info
                </th>
                <th class="text-left">
                    Inspection details
                </th>
            </tr>
            </thead>
            <tbody>
            <tr
                    class="cursor"
                    @click="() => { $router.push({name : 'HoseDetail',params: { hoseId : item.id }}) }"
                    v-for="(item,itemKey) in items"
                    :key="'inpectionRow'+itemKey"
            >

                <td v-if="clientAdmin">
                    <strong>Distributor:</strong> {{ item.distributorName }}<br/>
                    <strong>Customer:</strong> {{ item.customerName }}
                </td>
                <td v-else>
                    {{ distributor ? item.customerName : item.distributorName }}
                </td>
                <td class="py-2">
                    <div v-if="item.organisationZoneName">
                        <site-dropdown :title="item.organisationZoneName" :id="item.organisationZoneId"/>
                    </div>
                    <strong v-else>
                        Uninstalled
                    </strong>
                </td>
                <td class="py-2">
                    <hose-status :status="item.inspectionResult"></hose-status>
                    <small class="mb-2 mx-1 font-weight-bold d-block" v-if="item.inspectionResult === 0 || item.inspectionResult === 1">
                        Due : {{ $options.filters.formatDate(item.nextInspectionDate) }}
                    </small>
                </td>

                <td v-if="full" class="py-2">
                    <template v-if="item.hoseConfigurationName">
                                <div class="font-weight-bold">{{ item.hoseConfigurationName }}</div>


                                <hose-configuration-dropdown
                                        :id="item.hoseConfigurationId"></hose-configuration-dropdown>

                    </template>
                </td>
                <td v-if="full">
                    <div class="caption">
                        #{{ item.id }}
                    </div>
                    <div v-if="item.name" class="caption">
                        Ref : {{ item.name }}
                    </div>
                </td>
                <td class="py-2">
                    <span v-html="lastInspection(item)">{{ lastInspection(item) }}</span>
                </td>
            </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
    import {mapGetters} from "vuex";
    import ActionDropdown from "../ActionDropdown";
    import moment from "moment";
    import HoseStatus from "../hoses/HoseStatus";
    import HoseConfigurationDropdown from "../hose-configurations/HoseConfigurationDropdown";
    import SiteDropdown from "../sites/SiteDropdown";

    export default {
        name: 'InspectionList',
        components: {SiteDropdown, HoseConfigurationDropdown, HoseStatus, ActionDropdown},
        props: {
            items: {
                type: Array
            },
            full: true,
        },
        data() {
            return {
                badgeClass: 'd-inline-block rounded mt-1 py-1 px-2 font-weight-bold text-sm-left'
            }
        },
        computed: {
            ...mapGetters('auth', ['clientAdmin', 'distributor']),

        },
        methods: {
            statusToColor(item) {
                if (item.inspectionResult === 0) {
                    return "success";
                } else if (item.inspectionResult === 1) {
                    return "warning";
                } else if (item.inspectionResult === 2) {
                    return "error";
                } else {
                    return "light";
                }
            },
            lastInspection(item) {
                return `<div>` + (item.inspectionDate ? "Inspected by " + item.inspectionName + " on " + moment(item.inspectionDate).format("DD/MM/yyyy") : "Never inspected") + `<div>`
            },
            getDropdown(item) {
                return [
                    {
                        label: 'View full details',
                        icon: 'mdi-arrow-expand-all',
                        route: {name: 'HoseConfigurationView', params: {hoseConfigurationId: item.id}}
                    },
                    {
                        label: 'Edit config',
                        icon: 'mdi-pencil',
                        route: {name: 'HoseConfigurationEdit', params: {hoseConfigurationId: item.id}}
                    },
                    {
                        label: 'Delete config',
                        icon: 'mdi-delete-outline',
                        route: {name: 'HoseConfigurationDelete', params: {hoseConfigurationId: item.id}}
                    }
                ]
            }
        }
    }
</script>
