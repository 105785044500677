<template>
    <div>
        <div class="crud-header pa-4">

            <div class="crud-breadcrumbs">

            </div>

            <div class="crud-title d-flex align-center justify-space-between">

                <h1 class="primary--text d-flex flex-row align-center">
                    <v-icon  class="primary--text mr-2">
                        {{ icon }}
                    </v-icon>
                    <span>
                    {{ title }}
                        </span>
                </h1>
                <h1 v-if="organisation">{{ organisation.name }} : {{ organisation.accountCode }}</h1>
                <h1 v-else-if="systemAdmin">System Admin</h1>
                <h1 v-else-if="clientAdmin">Client Admin</h1>
                <div class="crud-actions">

                    <v-btn class="ml-1" small v-for="(item,key) in actions" :to="item.route" v-bind:key="key" depressed
                           :color="item.variant">
                        <v-icon small class="mr-1" v-if="item.icon">
                            {{ item.icon }}
                        </v-icon>
                        {{ item.title }}
                    </v-btn>

                </div>
            </div>

        </div>
        <div class="pa-4">
            <slot>

            </slot>
        </div>

        <v-dialog
                :persistent="true"
                content-class="dialog-right"
                v-model="dialog"
                fullscreen
                :hide-overlay="false"
                transition="slide-x-reverse-transition"
        >
            <v-card>
                <v-toolbar
                        flat
                >
                    <v-btn
                            icon
                            @click="closeDialog"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>

                </v-toolbar>
                <router-view/>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "CrudPage",
        props: {
            title: null,
            icon: {
                type: String,
                default() {
                    return 'mdi-office-building-outline';
                }
            },
            actions: {
                type: Array,
                default() {
                    return []
                }
            },
            breadcrumbs: {
                type: Array,
                default() {
                    return []
                }
            }
        },
        computed: {
            ...mapGetters('auth', ['clientAdmin', 'distributor', 'customer', 'organisation', 'systemAdmin']),
        },
        data: () => ({
            dialog: false,
        }),
        methods: {
            closeDialog() {
                this.$router.back()
            }
        },
        watch: {
            $route: {
                immediate: true,
                handler(to, from) {
                    if (to.meta.dialog) {
                        this.dialog = true
                    } else {
                        this.dialog = false
                    }
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .crud-header {
        background: var(--v-white-base);
        border-bottom: solid 1px var(--v-border-base);
    }
</style>
