import {httpRequest, endpoints} from "../network/hitag.client";
import router from '../../router/index.js';

const currentUser = () => {
    try {
        return JSON.parse(localStorage.getItem('user'));
    } catch (e) {
        return null;
    }
}

const configModule = {
    namespaced: true,
    state: () => ({
        sizeMappings: [],
        hoseTypes: [],
        boreSizes: [],
        manufacturers: [],
        connectionTypes: [],
        connections: [],
        loading: false,
        loadingError: {},
        hasLoaded : false,
    }),
    getters: {
        sizeMappings: state => state.sizeMappings,
        hoseTypes: state => state.hoseTypes,
        boreSizes: state => state.boreSizes,
        manufacturers: state => state.manufacturers,
        connectionTypes: state => state.connectionTypes,
        connections: state => state.connections,
        connectionsJumpSizes: state => state.connectionsJumpSizes,
        loading: state => state.loading,
        loadingError: state => state.loadingError,
        hasLoaded: state => state.hasLoaded,
    },
    mutations: {
        loading(state, data) {
            state.loading = data;
        },
        loadingError(state, data) {
            state.loadingError = data;
        },
        updateConfig(state, data) {
            state.sizeMappings = data.sizeMappings;
            state.hoseTypes = data.hoseTypes;
            state.boreSizes = data.boreSizes;
            state.manufacturers = data.manufacturers;
            state.connectionTypes = data.connectionTypes;
            state.connections = data.connections;
            state.connectionsJumpSizes = data.connectionsJumpSizes;
            state.hasLoaded = true;
        },
        updateHasLoaded(state,hasLoaded) {
            state.hasLoaded = hasLoaded;
        }
    },
    actions: {
        async forceReload({commit, dispatch}) {
            commit('updateHasLoaded', false);
            return dispatch('getConfig');
        },
        async getConfig({commit, dispatch, state}) {
            if(state.hasLoaded) return;
            commit('loadingError', {});
            commit('loading', true);
            try {
                let res = await httpRequest(endpoints.config.update, null);
                commit('updateConfig',res.data);
            } catch (e) {
                commit('loadingError', e.response.data);
                commit('updateHasLoaded', false);
            } finally {
                commit('loading', false);
            }
        },
    }
};
export default configModule;
