<template>
    <div v-if="organisation">
        <v-row dense>
            <v-col v-if="organisation.name" cols="6">
                <label-value-vertical label="Name" :value="organisation.name"/>
            </v-col>
            <v-col v-if="organisation.contactName" cols="6">
                <label-value-vertical label="Contact name" :value="organisation.contactName"/>
            </v-col>
            <v-col v-if="organisation.contactEmail" cols="6">
                <label-value-vertical label="Contact email" :value="organisation.contactEmail"/>
            </v-col>
            <v-col v-if="organisation.addressLine1" cols="6">
                <label-value-vertical label="Address line 1" :value="organisation.addressLine1"/>
            </v-col>
            <v-col v-if="organisation.addressLine2" cols="6">
                <label-value-vertical label="Address line 2" :value="organisation.addressLine2"/>
            </v-col>
            <v-col v-if="organisation.town" cols="6">
                <label-value-vertical label="Town" :value="organisation.town"/>
            </v-col>
            <v-col v-if="organisation.postCode" cols="6">
                <label-value-vertical label="Postcode" :value="organisation.postCode"/>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {endpoints, httpRequest} from "../../store/network/hitag.client";
    import GlobalErrorInline from "../GlobalErrorInline";
    import LabelValueVertical from "../LabelValueVertical";

    export default {
        name: "OrganisationView",
        components: {LabelValueVertical, GlobalErrorInline},
        props : {
            organisation : {}
        },
    }
</script>

<style scoped lang="scss">
    label {
        color: var(--v-textSubtle-base);
        font-weight: 700;
    }
    .col {
        padding-bottom: 2px;
        padding-top: 2px;
    }
</style>
