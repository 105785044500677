<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <crud-page icon="mdi-database-cog" title="Client manufacturers" :actions="[{
          icon: 'mdi-plus',
          title: 'Add new manufacturer',
          route: {name: 'ManufacturerAdd'},
          variant: 'success'
        }]">
    <paginated-result-loader
      title="Manage manufacturers"
      :listeners="['manufacturerUpdated']"
      :enable-exports="false"
      namespace="manufacturers">
    <template v-slot:default="props">
      <manufacturer-list :items="props.items"/>
    </template>
  </paginated-result-loader>

  </crud-page>
</template>

<style>
.tiptap-vuetify-editor__content {
  max-height: 350px;
}
</style>

<script>

import CrudPage from "../../components/CrudPage";
import {mapActions, mapGetters} from "vuex";
import {endpoints, httpRequest} from "@/store/network/hitag.client";
import GlobalErrorInline from "../../components/GlobalErrorInline";
import FormLoader from "@/components/FormLoader.vue";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from 'tiptap-vuetify'
import ManufacturerList from "@/components/manufacturers/ManufacturerList.vue";
import PaginatedResultLoader from "@/components/PaginatedResultLoader.vue";
import emitter from "@/utils/emitter";

export default {
  name: 'ClientSettings',
  components: {
    PaginatedResultLoader, ManufacturerList,
    FormLoader,
    GlobalErrorInline,
    CrudPage,
    TiptapVuetify,
  },
  computed: {
    ...mapGetters('auth', ['organisation', 'customer']),
    ...mapGetters('config', ['connectionTypes', 'manufacturers', 'hoseTypes']),
    ...mapGetters('clients', {
      'currentClient': 'item'
    })
  },
  created() {
    emitter.on('manufacturerUpdated',this.manufacturerUpdated);
  },
  beforeDestroy() {
    emitter.off('manufacturerUpdated',this.manufacturerUpdated);
  },
  methods: {
    manufacturerUpdated() {
      console.log("DONE HERE");
      this.$router.replace({name: 'ClientManufacturers'}).catch(() => {});
    }
  },
  data() {
    return {
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
    }
  },
  watch: {
  }
}
</script>
