<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <crud-page icon="mdi-clipboard-check-outline"  title="Quote requests">
        <paginated-result-loader
            :enable-exports="true"
                namespace="quotes">
            <template v-slot:default="props">
                <quote-list :items="props.items"></quote-list>
            </template>
        </paginated-result-loader>
    </crud-page>
</template>

<script>

  import CrudPage from "../../../components/CrudPage";
  import PaginatedResultLoader from "../../../components/PaginatedResultLoader";
  import QuoteList from "../../../components/quotes/QuoteList";
  export default {
    name: 'Quotes',
    components: {
        QuoteList,
        PaginatedResultLoader,
        CrudPage

    },
      computed : {
      }
  }
</script>
