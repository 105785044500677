<template>
    <div v-if="id == null"></div>
    <div v-else-if="loading">
        Loading
    </div>
    <div v-else-if="hasErrors">
        <global-error-inline :errors="errors"></global-error-inline>
      <v-btn small depressed color="primary" @click="loadLocation" >Retry</v-btn>
    </div>
    <div v-else>
            {{ location.trailName }}
    </div>
</template>

<script>
    import {endpoints, httpRequest} from "../../store/network/hitag.client";
    import GlobalErrorInline from "../GlobalErrorInline";
    import LabelValueVertical from "../LabelValueVertical";
    import LabelValueHorizontal from "../LabelValueHorizontal";

    export default {
        name: "SiteView",
        components: {LabelValueHorizontal, LabelValueVertical, GlobalErrorInline},
        props: {
            id: null,
        },
        data() {
            return {
                errors: {},
                loading: true,
                location: {},
            }
        },
        computed: {
            hasErrors() {
                return Object.keys(this.errors).length > 0;
            }
        },
        created() {
            if (this.id != null) {
                this.loadLocation();
            }
        },
        watch: {
            id(newVal, oldVal) {
                this.loadLocation();
            },
        },
        methods: {
            async loadLocation() {
                this.loading = true;
                this.errors = {};
                try {
                    let res = await httpRequest(endpoints.sites.getItem(this.id));
                    this.location = res.data;
                } catch (e) {
                    console.log("error", e.response.data);
                    this.errors = e.response.data
                } finally {
                    this.loading = false;
                }
            },
        }
    }
</script>

<style scoped lang="scss">

</style>
