<template>
  <div v-if="id == null && hoseId == null"></div>
  <div v-else-if="loading">
    Loading
  </div>
  <div v-else-if="hasErrors">
    <global-error-inline :errors="errors"></global-error-inline>
  </div>
  <div v-else>
    <label-value-horizontal v-if="hose.distributor && (clientAdmin || !distributor)" label="Distributor"
                            :value="hose.distributor.name"/>
    <label-value-horizontal v-if="hose.customer && (clientAdmin || distributor)" label="Customer"
                            :value="hose.customer.name"/>
    <label-value-horizontal v-if="hose.name" label="Tagged hose ref" :value="hose.name"/>
    <label-value-horizontal v-if="hose.organisationZone" label="Hose location"
                            :value="hose.organisationZone.name"/>
    <label-value-horizontal v-if="hose.manufactureDate" label="Manufacture date"
                            :value="$options.filters.formatDate(hose.manufactureDate)"/>
    <label-value-horizontal v-if="hose.buildDate" label="Build date"
                            :value="$options.filters.formatDate(hose.buildDate)"/>
    <label-value-horizontal v-if="hose.buildUser" label="Built by" :value="hose.buildUser.fullName"/>
    <label-value-horizontal v-if="hose.installationDate" label="Installation date"
                            :value="$options.filters.formatDate(hose.installationDate)"/>
    <label-value-horizontal v-if="hose.installationUser" label="Installed by"
                            :value="hose.installationUser.fullName"/>
    <label-value-horizontal label="Configuration name" :value="hoseConfiguration.name"/>

    <template v-if="!hoseConfiguration.hidden">
      <label-value-horizontal label="Hose type" :value="hoseConfiguration.type"/>
      <label-value-horizontal label="Bore size" :value="hoseConfiguration.boreSize"/>
      <label-value-horizontal label="Display pressure" :value="hoseConfiguration.displayPressure"/>
      <label-value-horizontal label="Hose length (overall) /mm" :value="hoseConfiguration.length"/>
      <label-value-horizontal label="Connection 1" :value="hoseConfiguration.connection1"/>
      <label-value-horizontal label="Connection 1 type" :value="hoseConfiguration.connection1Type"/>
      <label-value-horizontal label="Connection 1 size" :value="hoseConfiguration.connection1JumpSize"/>
      <label-value-horizontal label="Connection 2" :value="hoseConfiguration.connection2"/>
      <label-value-horizontal label="Connection 2 type" :value="hoseConfiguration.connection2Type"/>
      <label-value-horizontal label="Connection 2 size" :value="hoseConfiguration.connection2JumpSize"/>
      <label-value-horizontal label="Orientation" :value="hoseConfiguration.orientation"/>
      <label-value-horizontal label="Hose manufacturer" :value="hoseConfiguration.manufacturer"/>
      <label-value-horizontal label="Has sleeving?" :value="hoseConfiguration.sleeving === 1 ? 'Yes' : 'No'"/>
      <label-value-horizontal label="Test certificate?"
                              :value="hoseConfiguration.testCertificate === 1 ? 'Yes' : 'No'"/>
      <label-value-horizontal label="Conformity certificate?"
                              :value="hoseConfiguration.conformityCertificate === 1 ? 'Yes' : 'No'"/>
    </template>
    <label-value-horizontal v-if="hose.notes" label="Configuration notes" :value="hoseConfiguration.notes"/>

    <label-value-horizontal v-if="hose.id" label="Tagged hose ID" :value="hose.id"/>

  </div>
</template>

<script>
import {endpoints, httpRequest} from "../../store/network/hitag.client";
import GlobalErrorInline from "../GlobalErrorInline";
import LabelValueVertical from "../LabelValueVertical";
import LabelValueHorizontal from "../LabelValueHorizontal";
import {mapGetters} from "vuex";

export default {
  name: "HoseConfigurationView",
  components: {LabelValueHorizontal, LabelValueVertical, GlobalErrorInline},
  props: {
    id: null,
    hoseId: null,
    refreshToken: null,
  },
  data() {
    return {
      errors: {},
      loading: true,
      hoseConfiguration: {},
      hose: {},
    }
  },
  computed: {
    ...mapGetters('auth', ['distributor', 'clientAdmin']),
    hasErrors() {
      return Object.keys(this.errors).length > 0;
    }
  },
  created() {
    if (this.id != null) {
      this.loadHoseConfig();
    } else if (this.hoseId != null) {
      this.loadHose();
    }
  },
  watch: {
    id(newVal, oldVal) {
      this.loadHoseConfig();
    },
    hoseId(newVal, oldVal) {
      this.loadHose();
    },
    refreshToken(newVal, oldVal) {
      if (this.id != null) {
        this.loadHoseConfig();
      } else if (this.hoseId != null) {
        this.loadHose();
      }
    }
  },
  methods: {
    async loadHoseConfig() {
      this.loading = true;
      try {
        let res = await httpRequest(endpoints.hoseConfigurations.getItem(this.id));
        this.hoseConfiguration = res.data;
      } catch (e) {
        console.log("error", e.response.data);
        this.errors = e.response.data
      } finally {
        this.loading = false;
      }
    },
    async loadHose() {
      this.loading = true;
      try {
        let res = await httpRequest(endpoints.hoses.getItem(this.hoseId));
        this.hose = res.data;
        this.hoseConfiguration = res.data.hoseConfiguration;
        this.$emit('hoseLoaded', res.data);
        console.log("NEW HOSE IS ", res.data);
        console.log("NEW HOSE CONFIG ", res.data.hoseConfiguration);
      } catch (e) {
        console.log("error", e.response.data);
        this.errors = e.response.data
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped lang="scss">
label {
  color: var(--v-textSubtle-base);
  font-weight: 700;
}

.col {
  padding-bottom: 2px;
  padding-top: 2px;
}
</style>
