<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <form-loader
            class="px-4"
            form-type="view"
            title="View quotation"
            :id="quoteId"
            btn-icon="mdi-send"
            namespace="quotes">
        <template #default="{item,errors,onUpdate}">
            <v-chip class="mb-2" v-if="item.completedAt" color="success" >
                Completed on {{ $options.filters.formatDate(item.completedAt) }}
            </v-chip>
            <v-row no-gutters>
                <v-col class="mb-3" v-if="!item.completedAt" cols="12">
                    <v-btn :loading="completeLoading" depressed dense @click="() => { completeQuote(item.id) }" color="success">
                        <v-icon>mdi-check</v-icon> Mark as completed
                    </v-btn>
                </v-col>
                <v-col v-if="item.message" cols="12">
                    <h3 class="card-header mb-2">Message</h3>
                    <div  class="light rounded pa-3 mb-3">
                        {{ item.message }}
                    </div>
                </v-col>
                <v-col cols="12">
                    <h3 class="card-header mb-2">Hose info</h3>
                    <div class="light rounded pa-3 mb-3">
                        <hose-configuration-view :hose-id="item.hoseId"></hose-configuration-view>
                    </div>
                </v-col>
                <v-col v-if="!clientAdmin" cols="12">
                    <h3 class="card-header mb-2">{{ quoteTitle(item) }}</h3>
                    <div class="light rounded pa-3">
                        <organisation-view :organisation="item.requesterOrganisation.id === organisation.id ?  item.receiverOrganisation : item.requesterOrganisation"/>
                    </div>
                </v-col>
                <v-col v-else cols="12">
                    <h3 class="card-header mb-2">Requested by</h3>
                    <div class="light rounded pa-3">
                        <organisation-view :organisation="item.requesterOrganisation"/>
                    </div>
                    <h3 class="card-header mb-2 mt-3">Received by</h3>
                    <div class="light rounded pa-3">
                        <organisation-view :organisation="item.receiverOrganisation"/>
                    </div>
                </v-col>

            </v-row>
        </template>
    </form-loader>
</template>

<script>
    import FormLoader from "../../../components/FormLoader";
    import {mapActions, mapGetters} from "vuex";
    import ApiSelectBox from "../../../components/ApiSelectBox";
    import HoseConfigurationView from "../../../components/hose-configurations/HoseConfigurationView";
    import OrganisationView from "../../../components/organisations/OrganisationView";

    export default {
        name: "QuoteAdd",
        components: {OrganisationView, HoseConfigurationView, ApiSelectBox, FormLoader},
        props: {
            quoteId: null,
        },
        methods: {
            ...mapActions('quotes',['completeQuote']),
            quoteTitle(item) {
                console.log("ITEM IS ",item);
                if(this.distributor) {
                    if(item.requesterOrganisationId === this.organisation.id) {
                        return "Offered to:";
                    } else {
                        return "Requested from:";
                    }
                } else if(this.customer) {
                    if(item.requesterOrganisationId === this.organisation.id) {
                        return "Request sent to:";
                    } else {
                        return "Offered from:";
                    }
                } else {
                    return "View quote";
                }
            }
        },
        watch : {
            completeItem(newVal) {
                this.$store.commit('quotes/updateItem',newVal);
            }
        },
        computed: {
            ...mapGetters('users', ['item']),
            ...mapGetters('quotes', ['completeItem','completeLoading']),
            ...mapGetters('auth', ['user', 'clientAdmin', 'distributor','customer', 'organisation']),
        }
    }
</script>

<style scoped>

</style>
