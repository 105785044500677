<template>
  <v-app>
    <v-navigation-drawer
        color="white"
        permanent
        :app="true"
    >


      <router-link :to="{name: 'Home'}">
        <v-img
            alt="Hi Tag - hose intelligence"
            class="ma-4"
            contain
            :src="logoUrl"
            width="140"
        />


      </router-link>

      <div class="text-center">
        <v-btn @click="stopImpersonating" small class="mx-3 my-3" outlined color="accent" depressed v-if="isImpersonating">
          <v-icon size="14" class="mr-2">mdi-exit-to-app</v-icon>
          Back to client admin
        </v-btn>
      </div>

      <user-side-menu/>

      <dashboard-side-menu class="mt-1"/>

    </v-navigation-drawer>

    <v-main class="bg">
      <slot></slot>
    </v-main>

  </v-app>
</template>

<style lang="scss">
.bg {
  background: var(--v-light-base);
}

.dialog-right {
  left: auto !important;
  right: 0;
  align-self: flex-end;
  max-width: 500px;
}
</style>

<script>
import DashboardSideMenu from "../components/navigation/DashboardSideMenu";
import UserSideMenu from "../components/navigation/UserSideMenu";
import AlertDisplay from "../components/AlertDisplay";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "PortalLayout",
  components: {AlertDisplay, UserSideMenu, DashboardSideMenu},
  data() {
    return {
      mini: true,
    }
  },
  watch: {
    '$route'(to, from) {
      if (to.path !== from.path) {
        this.mini = true;
      }
    }
  },

  computed : {
    ...mapGetters('clients',['item']),
    logoUrl() {
      return require('@/assets/'+ this.item.theme +'/logo.png');
    },
    isImpersonating() {
      try {
        var token = localStorage.getItem('impersonatingAccessToken');
        console.log("TOKEN IS",token);
        return (localStorage.getItem('impersonatingAccessToken') || null) != null;
      } catch (e) {
        console.log("impersonate err",e);
        return false;
      }
    },
  },
  methods: {
    async stopImpersonating() {
      await localStorage.removeItem('impersonatingAccessToken');
      await localStorage.removeItem('impersonatingRefreshToken');
      window.location = "/select-organisation";
    }
  }

}
</script>

