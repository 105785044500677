<template>
    <v-timeline-item
            :color="color"
            small
    >
        <div class="px-2">
            <h3 v-if="title">{{ title }}</h3>
            <div v-if="subtitle"><strong>{{ subtitle }}</strong></div>
            <slot>

            </slot>
            <div v-if="text" class="text-caption">
                {{ text }}
            </div>
            <div class="my-2" v-if="uploads.length > 0">
                <a @click="selectedUpload = upload" class="rounded pa-2 d-inline-block mr-2 mb-2 light"
                   v-for="(upload,uploadKey) in uploads"
                   v-bind:key="upload.id">
                    <upload-image-loader :upload="upload"/>
                </a>
            </div>
        </div>

        <v-dialog
                width="1200"
                v-model="dialogOpen"
        >
            <div class="white rounded">
                <upload-image-loader :upload="selectedUpload" :size="1" :width="1200" :thumbnail="false"/>
                <div class="mt-2" v-if="uploads.length > 0">
                    <a @click="selectedUpload = upload" class="rounded pa-2 d-inline-block mr-2 mb-2 light"
                       v-for="(upload,uploadKey) in uploads"
                       v-bind:key="upload.id">
                        <upload-image-loader :upload="upload"/>
                    </a>
                </div>
            </div>
        </v-dialog>

    </v-timeline-item>
</template>
<script>
    import UploadImageLoader from "../../../components/UploadImageLoader";

    export default {
        name: 'HoseTimelineItem',
        components: {UploadImageLoader},
        data() {
            return {
                selectedUpload: null
            }
        },
        computed: {
            dialogOpen: {
                get() {
                    return this.selectedUpload != null
                },
                set(value) {
                    if (!value) {
                        this.selectedUpload = null;
                    }
                }
            }
        },
        props: {
            title: null,
            subtitle: null,
            text: null,
            uploads: {
                type: Array,
                default() {
                    return [];
                }
            },
            color : {
                type: String,
                default() {
                    return "secondary"
                }
            }

        }
    }
</script>
