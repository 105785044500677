<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <crud-page icon="mdi-database-cog" title="Client settings">
    <form-loader
        form-type="edit"
        :title="null"
        btn-text="Save client settings"
        :id="currentClient.id"
        namespace="clientsEdit">

      <template #default="{item,errors,onUpdate}">
        <v-card class="pa-5 mb-4">
          <v-row>
            <v-col cols="12" md="6">
              <v-checkbox
                  label="Manual hose config entry"
                  hint="Users must manually enter hose configurations field values (No dropdown options)"
                  :persistent-hint="true"
                  v-model="item.manualHoseConfiguration"
                  @input="onUpdate(item)"
              />
            </v-col>

          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-textarea
                  label="Meta Title"
                  v-model="item.metaTitle"
                  @input="onUpdate(item)"
                  :error="errors.metaTitle != null"
                  :error-messages="errors.metaTitle"
              />
            </v-col>
            <v-col cols="12" md="6">

              <v-textarea
                  label="Meta Description"
                  v-model="item.metaDescription"
                  @input="onUpdate(item)"
                  :error="errors.metaDescription != null"
                  :error-messages="errors.metaDescription"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <div class="mb-2">Terms and conditions:</div>
              <tiptap-vuetify
                  v-model="item.termsAndConditions"
                  :extensions="extensions"
              />
            </v-col>
            <v-col cols="12" md="6">
              <div class="mb-2">Privacy Policy:</div>
              <tiptap-vuetify

                  v-model="item.privacyPolicy"
                  :extensions="extensions"
              />
            </v-col>
          </v-row>
        </v-card>
      </template>
    </form-loader>
  </crud-page>
</template>

<style>
.tiptap-vuetify-editor__content {
  max-height: 350px;
}
</style>

<script>

import CrudPage from "../../components/CrudPage";
import {mapActions, mapGetters} from "vuex";
import {endpoints, httpRequest} from "@/store/network/hitag.client";
import GlobalErrorInline from "../../components/GlobalErrorInline";
import FormLoader from "@/components/FormLoader.vue";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from 'tiptap-vuetify'
import ManufacturerList from "@/components/manufacturers/ManufacturerList.vue";
import PaginatedResultLoader from "@/components/PaginatedResultLoader.vue";
import emitter from "@/utils/emitter";

export default {
  name: 'ClientSettings',
  components: {
    PaginatedResultLoader, ManufacturerList,
    FormLoader,
    GlobalErrorInline,
    CrudPage,
    TiptapVuetify,
  },
  computed: {
    ...mapGetters('auth', ['organisation', 'customer']),
    ...mapGetters('config', ['connectionTypes', 'manufacturers', 'hoseTypes']),
    ...mapGetters('clients', {
      'currentClient': 'item'
    })
  },
  data() {
    return {
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
    }
  },
  watch: {
  }
}
</script>
