<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <form-loader
            class="px-4"
            :form-type="formType"
            :title="formTitle"
            :before-save="beforeSave"
            :btn-text="formTitle"
            btn-icon="mdi-send"
            namespace="quotes">
        <template #default="{item,errors,onUpdate}">
            <div class="light px-3">
                <hose-configuration-view v-on:hoseLoaded="onHoseLoaded" :hose-id="hoseId"></hose-configuration-view>
            </div>
            <div class="pt-3">
                <api-select-box
                        v-if="customer"
                        label="Organisation"
                        hint="Select organisation to send to"
                        item-text="name"
                        item-value="id"
                        v-model="receiverOrganisationId"
                        namespace="organisationSelect"
                        :error="errors.receiverOrganisationId != null"
                        :error-messages="errors.receiverOrganisationId"
                />
                <v-textarea
                        class="mt-3"
                        label="Leave an optional message"
                        v-model="item.message"
                        @input="onUpdate(item)"
                        :error="errors.message != null"
                        :error-messages="errors.message"/>
            </div>
        </template>
    </form-loader>
</template>

<script>
    import FormLoader from "../../../components/FormLoader";
    import {mapGetters} from "vuex";
    import ApiSelectBox from "../../../components/ApiSelectBox";
    import HoseConfigurationView from "../../../components/hose-configurations/HoseConfigurationView";

    export default {
        name: "QuoteAdd",
        components: {HoseConfigurationView, ApiSelectBox, FormLoader},
        data() {
            return {
                receiverOrganisationId: null
            }
        },
        props: {
            hoseId: null,
            quoteOrganisationId: null,
        },
        methods: {
            onHoseLoaded(hose) {
                console.log("HOSE EMITTED ", hose);
                if (this.receiverOrganisationId == null) {
                    if(this.distributor) {
                        this.receiverOrganisationId = hose.customerOrganisationId;
                    } else {
                        this.receiverOrganisationId = hose.distributorOrganisationId;
                    }
                }
            },
            beforeSave(item) {
                item.requestorOrganisationId = this.organisation.id;
                item.receiverOrganisationId = this.receiverOrganisationId;
                item.hoseId = this.hoseId;
                return item;
            },
        },
        computed: {
            ...mapGetters('users', ['item']),
            ...mapGetters('auth', ['user', 'clientAdmin', 'distributor','customer', 'organisation']),
            formType() {
                if (this.$router.currentRoute.name === "QuoteView") return 'view';
                return "add";
            },
            formTitle() {
                return this.distributor ? "Offer quote" : "Request quote";
            }
        }
    }
</script>

<style scoped>

</style>
