<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <form-loader
            class="px-4"
            :form-type="formType"
            :title="formTitle"
            :before-save="beforeSave"
            :id="organisationId"
            namespace="organisations">
        <template #default="{item,errors,onUpdate}">

            <v-text-field
                    label="Organisation name"
                    v-model="item.name"
                    @input="onUpdate(item)"
                    :error="errors.name != null"
                    :error-messages="errors.name"
            />

            <v-text-field label="Contact name"
                          v-model="item.contactName"
                          @input="onUpdate(item)"
                          :error="errors.contactName != null"
                          :error-messages="errors.contactName"/>

            <v-text-field
                    label="Contact email"
                    v-model="item.contactEmail"
                    @input="onUpdate(item)"
                    :error="errors.contactEmail != null"
                    :error-messages="errors.contactEmail"/>

            <v-text-field
                    label="Address line 1"
                    v-model="item.addressLine1"
                    @input="onUpdate(item)"
                    :error="errors.addressLine1 != null"
                    :error-messages="errors.addressLine1"/>

            <v-text-field
                    label="Address line 2"
                    v-model="item.addressLine2"
                    @input="onUpdate(item)"
                    :error="errors.addressLine2 != null"
                    :error-messages="errors.addressLine2"/>

            <v-text-field
                    label="Town/City"
                    v-model="item.town"
                    @input="onUpdate(item)"
                    :error="errors.town != null"
                    :error-messages="errors.town"/>

            <v-text-field
                    label="Post code"
                    v-model="item.postCode"
                    @input="onUpdate(item)"
                    :error="errors.postCode != null"
                    :error-messages="errors.postCode"/>


                <v-text-field
                        label="Inspection timeline"
                        persistent-hint
                        type="number"
                        suffix="months"
                        hint="The frequency this organisation requires inspections"
                        @input="onUpdate(item)"
                        v-model="item.hoseInspectionMonths"
                        :error="errors.hoseInspectionMonths != null"
                        :error-messages="errors.hoseInspectionMonths"/>
                <v-text-field
                        label="Replacement timeline"
                        persistent-hint
                        suffix="months"
                        type="number"
                        hint="Hoses installed for more than this time will be due for replacement"
                        @input="onUpdate(item)"
                        v-model="item.hoseReplacementMonths"
                        :error="errors.hoseReplacementMonths != null"
                        :error-messages="errors.hoseReplacementMonths"/>

            <v-checkbox
                    v-if="clientAdmin"
                    label="This organisation is a distributor"
                    v-model="item.isDistributor"
                    @input="onUpdate(item)"
                    :error="errors.isDistributor != null"
                    :error-messages="errors.isDistributor"/>

            <api-select-box
                    :custom-filters="[{key : 'isDistributor', value : true}, {key : 'excludeId', value : organisationId}]"
                    v-if="clientAdmin && !item.isDistributor"
                    label="Distributor"
                    hint="Distibutor for this customer"
                    item-text="name"
                    item-value="id"
                    v-model="item.distributorId"
                    @input="onUpdate(item)"
                    namespace="organisationSelect"
                    :error="errors.email != null"
                    :error-messages="errors.organisationIds"
            />


        </template>
    </form-loader>
</template>

<script>
    import FormLoader from "../../../components/FormLoader";
    import {mapGetters} from "vuex";
    import ApiSelectBox from "../../../components/ApiSelectBox";

    export default {
        name: "OrganisationAdd",
        components: {ApiSelectBox, FormLoader},
        props: {
            organisationId: null,
        },
        computed: {
            ...mapGetters('users', ['item']),
            ...mapGetters('auth', ['user', 'clientAdmin', 'customer', 'distributor', 'currentOrganisationId']),
            formType() {
                if (this.$router.currentRoute.name === "OrganisationDelete") return 'delete';
                if (this.$router.currentRoute.name === "OrganisationView") return 'view';
                return this.organisationId != null ? "edit" : "add";
            },
            formTitle() {
                var orgWord = this.clientAdmin ? "organisation" : "customer";
                if (this.formType === "delete") {
                    return "Delete " + orgWord;
                } else if (this.formType === "view") {
                    return "View " + orgWord;
                } else if (this.formType === "add") {
                    return "Create " + orgWord;
                } else if (this.formType === "edit") {
                    return "Edit " + orgWord;
                }
            }
        },
        methods: {
            beforeSave(item) {
                if (!this.clientAdmin && this.distributor) {
                    item.distributorId = this.currentOrganisationId;
                }
                return item;
            }
        }
    }
</script>

<style scoped>

</style>
