<template>
    <centered-layout :title="currentTab === 0 ? 'Login to '+$store.state.clients.item.name : 'Register for '+$store.state.clients.item.name">
        <v-tabs v-model="tab">
            <v-tab>
                <v-icon small class="mr-1">mdi-account-lock-outline</v-icon>
                Login here
            </v-tab>
            <v-tab>
                <v-icon small class="mr-1">mdi-account-plus-outline</v-icon>
                Register here
            </v-tab>
            <v-tab-item>
                <v-form @submit.prevent="login({email: email, password: password})">
                    <div class="pa-5">
                        <v-text-field
                                autocomplete="false"
                                :loading="loading"
                                :disabled="loading"
                                v-model="email"
                                label="Email"
                                :persistent-placeholder="true"
                                :error="loginError.email != null"
                                :error-messages="loginError.email"
                        ></v-text-field>

                        <v-text-field
                                autocomplete="false"
                                :loading="loading"
                                :disabled="loading"
                                type="password"
                                v-model="password"
                                label="Password"
                                hide-details="auto"
                                :persistent-placeholder="true"
                                :error="loginError.password != null"
                                :error-messages="loginError.password"
                        ></v-text-field>

                        <global-error-inline :errors="loginError"/>

                        <v-btn type="submit" class="mt-4" color='primary' depressed dark :loading="loading">
                            <v-icon small class="mr-1">mdi-account-lock-outline</v-icon>
                            Login
                        </v-btn>
                        <div class="py-3">
                            <router-link :to="{name:'ForgotPassword'}">Forgot your password?</router-link>
                        </div>
                    </div>
                </v-form>
            </v-tab-item>
            <v-tab-item>
                <div class="text-center pa-6" v-if="unverifiedUser && registerEmail === unverifiedUser.email">
                    <v-icon large color="success">mdi-email</v-icon>
                    <h2 class="mb-3 mt-4">Check your email ({{ unverifiedUser.email }})</h2>
                    <p>We've sent you an email to verify you own it. Please follow the instructions within this email to
                        complete your registration.</p>
                </div>
                <v-form v-else
                        @submit.prevent="formRegister">
                    <div class="pa-5">
                        <v-text-field
                                autocomplete="false"
                                :loading="loading"
                                :disabled="loading"
                                v-model="registerFirstName"
                                label="First name"
                                :persistent-placeholder="true"
                                :error="registerError.firstName != null"
                                :error-messages="registerError.firstName"
                        ></v-text-field>

                        <v-text-field
                                autocomplete="false"
                                :loading="loading"
                                :disabled="loading"
                                v-model="registerLastName"
                                label="Last name"
                                :persistent-placeholder="true"
                                :error="registerError.lastName != null"
                                :error-messages="registerError.lastName"
                        ></v-text-field>

                        <v-text-field
                                autocomplete="false"
                                :loading="loading"
                                :disabled="loading"
                                v-model="registerEmail"
                                label="Email"
                                :persistent-placeholder="true"
                                :error="registerError.email != null"
                                :error-messages="registerError.email"
                        ></v-text-field>


                        <vue-recaptcha
                                ref="register-captcha"
                                :sitekey="recaptchaKey"
                                       v-on:verify="onRecaptchaVerify"
                                       v-on:expired="onRecaptchaExpired"
                                       v-on:render="onRecaptchaRendered"
                                       v-on:error="onRecaptchaError"
                                       :loadRecaptchaScript="true"></vue-recaptcha>

                        <global-error-inline :errors="registerError"/>

                        <v-btn v-if="recaptchaResponse !== null" type="submit" class="mt-4" color='primary' depressed dark :loading="loading">
                            <v-icon small class="mr-1">mdi-account-plus-outline</v-icon>
                            Register
                        </v-btn>
                    </div>
                </v-form>
            </v-tab-item>
        </v-tabs>


    </centered-layout>
</template>

<script>
    import {mapActions, mapGetters, mapState} from "vuex";
    import CenteredLayout from "../layouts/CenteredLayout";
    import GlobalErrorInline from "../components/GlobalErrorInline";
    import VueRecaptcha from 'vue-recaptcha';

    export default {
        name: 'Login',

        data: () => ({
            email: null,
            password: null,
            registerFirstName: null,
            registerLastName: null,
            registerEmail: null,
            currentTab: 0,
            recaptchaResponse : null,
        }),
        mounted() {
            this.currentTab = this.tab;
        },
        computed: {
            recaptchaKey() {
                return process.env.VUE_APP_RECAPTCHA_SITE_KEY;
            },
            tab: {
                get() {
                    return this.$router.currentRoute.name === 'Login' ? 0 : 1;
                },
                set(val) {
                    console.log("TAB CHANGE", val);
                    this.$router.replace({name: val === 1 ? 'Register' : 'Login'}).catch(() => {
                    });
                    this.currentTab = val;
                }
            },
            ...mapGetters('auth', ['loading', 'user', 'multiOrg', 'clientAdmin', 'loginError', 'registerError', 'unverifiedUser','systemAdmin']),
        },
        watch: {
            user(newVal, oldVal) {
                if (newVal == null) return;
                if (this.clientAdmin || this.systemAdmin) {
                    this.$router.push({name: 'Portal', params: {currentOrganisationId: 'admin'}});
                } else if (this.multiOrg) {
                    this.$router.push({name: 'OrganisationSelect'});
                } else if (newVal.organisations.length > 0) {
                    this.$router.push({
                        name: 'Portal',
                        params: {currentOrganisationId: newVal.organisations[0].id}
                    });
                } else {
                    this.$router.push({
                        name: 'OrganisationCreate'
                    });
                }
            }
        },
        methods: {
            ...mapActions('auth', ['login', 'register']),
            formRegister() {
                this.$refs['register-captcha'].reset();
                this.register({email: this.registerEmail, firstName: this.registerFirstName, lastName: this.registerLastName, recaptchaResponse : this.recaptchaResponse})
            },
            onRecaptchaVerify(response) {
                console.log("VERIFIED", response);
                this.recaptchaResponse = response;
            },
            onRecaptchaExpired() {
                this.recaptchaResponse = null;
                console.log("RECAPTCHA EXPIRED");
            },
            onRecaptchaRendered(id) {
                console.log("RENDERED", id);
            },
            onRecaptchaError() {
                this.recaptchaResponse = null;
                console.log("ERROR IN RECAPTCHA");
            }
        },
        components: {GlobalErrorInline, CenteredLayout, VueRecaptcha},
    }
</script>
