<template>
  <div class="d-flex align-center">

    <div class="flex-fill mr-2">
      <slot v-if="!manualEntry && !forcedManualEntry"></slot>
      <v-text-field
          ref="manualEntry"
          v-else
          :label="label"
          :hint="hint"
          :error-messages="errors"
          :error="errors != null"
          v-model="modelValue"/>
    </div>

    <v-tooltip v-if="!forcedManualEntry" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs"
                v-on="on" :color="manualEntry ? 'success' : 'subtle'" @click="manualEntry = !manualEntry">mdi-keyboard
        </v-icon>
      </template>
      <span>Manual entry</span>
    </v-tooltip>
  </div>
</template>

<script>
import validator from "validator";

export default {
  name: "ManualEntry",
  data: () => ({
    inputValue: null,
    manualEntry: null
  }),
  props: {
    forcedManualEntry: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    hint: {
      type: String,
      default: null
    },
    errors: {
      type: Array,
      default: null
    },
    value: {
      type: String,
      default: null
    },
    initialManualEntry: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.inputValue = this.value;
    this.manualEntry = this.initialManualEntry;
  },
  computed: {
    modelValue: {
      get() {
        return this.inputValue != null ? (validator.isUUID(this.inputValue) ? null : this.inputValue) : null;
      },
      set(newValue) {
        console.log("Setting value", newValue);
        if (!validator.isUUID(newValue)) {
          this.inputValue = newValue;
          this.$emit('input', newValue);
        }
      }
    }
  },
  watch: {
    manualEntry(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.$refs.manualEntry.focus();
        });
      }
    },
    value(newValue) {
      this.inputValue = newValue;
    },
    inputValue(newValue) {
      this.$emit('input', newValue);
    }
  }
}
</script>

<style scoped>

</style>
