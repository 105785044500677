import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({

    icons: {
        iconfont: 'mdi',
    },
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            defaultTheme: 'hitag',
            hitag: {
                primary: '#c21632',
                secondary: '#b0bec5',
                info: '#314dba',
                error: '#b71c1c',
                warning : '#E8B618',
                success : '#5DB716',
                white : '#ffffff',
                text : '#333333',
                textSubtle : '#938788',
                light: '#f7f7f7',
                border: '#cccccc',
            },
            pirtek: {
                primary: '#012169',
                secondary: '#b0bec5',
                info: '#314dba',
                error: '#b71c1c',
                warning : '#E8B618',
                success : '#5DB716',
                white : '#ffffff',
                text : '#333333',
                textSubtle : '#938788',
                light: '#f7f7f7',
                border: '#cccccc',
            },
            hydraquip: {
                primary: '#f90404',
                secondary: '#b0bec5',
                info: '#314dba',
                error: '#b71c1c',
                warning : '#E8B618',
                success : '#5DB716',
                white : '#ffffff',
                text : '#333333',
                textSubtle : '#938788',
                light: '#f7f7f7',
                border: '#cccccc',
            },
            flowtech: {
                primary: '#027dc5',
                secondary: '#b0bec5',
                info: '#314dba',
                error: '#b71c1c',
                warning : '#E8B618',
                success : '#5DB716',
                white : '#ffffff',
                text : '#333333',
                textSubtle : '#938788',
                light: '#f7f7f7',
                border: '#cccccc',
            },
        },
    },
});
