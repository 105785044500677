import emitter from "@/utils/emitter";

export default function paginationMixin() {
    return {
        props: {
            customFilters: {
                type: Array,
                default() {
                    return []
                }
            },
            queryParams: {
                type: Object,
                default() {
                    return {}
                }
            },
            countReference: null,
        },
        created() {
            var query = this.$router.currentRoute.query;
            if(query.filters) {
                this.filters = [];
            } else if(query.clear) {
                this.filters = [];
            } else {
                this.getItems();
            }
        },
        computed: {
            items() {
                return this.$store.getters[this.namespace + '/items'];
            },
            totalPages() {
                return this.$store.getters[this.namespace + '/totalPages']
            },
            totalItems() {
                return this.$store.getters[this.namespace + '/totalItems'];
            },
            perPage() {
                return this.$store.getters[this.namespace + '/perPage']
            },
            loading() {
                return this.$store.getters[this.namespace + '/loading']
            },
            loadingError() {
                return this.$store.getters[this.namespace + '/loadingError']
            },
            currentPage: {
                get() {
                    return this.$store.getters[this.namespace + '/currentPage']
                },
                set(value) {
                    this.$store.dispatch(this.namespace + '/updatePage', value)
                }
            },
            filters: {
                get() {
                    var currentFilters = this.$store.getters[this.namespace + '/filters'];
                    console.log("CURRENT FILTERS " + this.namespace, currentFilters);
                    return currentFilters;
                },
                set(value) {
                    this.$store.dispatch(this.namespace + '/updateFilters', value).then(() => {
                        this.getItems();
                    })
                }
            },
        },
        methods: {
            getItems() {
                var query = this.$router.currentRoute.query;
                var customFilters = this.customFilters;
                console.log("NEW QUERY IS",query);
                if(query.filters) {
                    var decoded = decodeURI(query.filters);
                    console.log("DECODED QUERY IS",decoded);
                    var parsed = JSON.parse(decoded);
                    _.each(parsed,function(obj) {
                        customFilters.push(obj);
                    });
                    this.$router.replace({name : this.$router.currentRoute.name});
                }
                if(query.clear){
                    this.$router.replace({name : this.$router.currentRoute.name});
                }
                console.log("CUSTOM FILTERS ARE",customFilters);
                return this.$store.dispatch(this.namespace + '/getItems', {customFilters, queryParams : this.queryParams})
            }
        },
        watch: {
            currentPage(newVal, oldVal) {
                if (newVal !== oldVal)
                    this.getItems();
            },
            customFilters: {
                handler(newVal, oldVal) {
                    if (newVal !== oldVal)
                        this.getItems();
                },
                deep: true
            }
        }
    }
}
