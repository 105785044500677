import {httpRequest, endpoints} from "../network/hitag.client";
import router from '../../router/index.js';
import * as _ from "lodash";
import crud, {baseActions, baseGetters, baseMutations, baseState} from "./baseStore";

function state(){
    return {
        ...baseState(),
        completeLoading : false,
        completeError : {},
        completeItem : {},
    }
}

function getters(){
    return {
        ...baseGetters(),
        completeLoading : state => state.completeLoading,
        completeError : state => state.completeError,
        completeItem : state => state.completeItem,
    }
}

function mutations(){
    return {
        ...baseMutations(),
        setCompleteLoading(state,data) {
            state.completeLoading = data;
        },
        setCompleteError(state,data) {
            state.completeError = data;
        },
        setCompleteItem(state,data) {
            state.completeItem = data;
        }
    }
}

function actions(){
    return {
        ...baseActions('quotes'),
        async completeQuote({commit, dispatch},id) {
            commit('setCompleteError', {});
            commit('setCompleteLoading', id);
            try {
                var res = await httpRequest(endpoints.quotes.completeQuote(id));
                commit('setCompleteItem', res.data);
                await dispatch('getItems')
            } catch (e) {
                console.log("pending error", e);
                console.log("pending error data", e.response.data);
                commit('setCompleteError', e.response.data);
            } finally {
                commit('setCompleteLoading', null);
            }
        }
    }
}

function quotesModule()
{
    return {
        namespaced: true,
        state: state(),
        getters: getters(),
        mutations: mutations(),
        actions: actions()
    }
}
export default quotesModule;
