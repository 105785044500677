<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <crud-page icon="mdi-clipboard-list-outline" title="Inspections">
        <paginated-result-loader
            :enable-exports="true"
                namespace="inspections">
            <template v-slot:default="props">
                <inspection-list :full="true" :items="props.items"/>
            </template>
        </paginated-result-loader>
    </crud-page>
</template>

<script>

  import CrudPage from "../../../components/CrudPage";
  import PaginatedResultLoader from "../../../components/PaginatedResultLoader";
  import HoseList from "../../../components/hoses/HoseList";
  import InspectionList from "../../../components/inspections/InspectionList";
  export default {
    name: 'Inspections',
    components: {
        InspectionList,
        HoseList,
        PaginatedResultLoader,
        CrudPage
    },
  }
</script>
