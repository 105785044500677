<template>
  <crud-page title="Your profile">
    <v-row align="stretch">
      <v-col class="d-flex flex-column">
        <h2 class="card-header mb-2">Basic details</h2>
        <v-card class="pa-3 flex-fill d-flex flex-column justify-end">
          <v-text-field
              label="First name"
              v-model="basic.firstName"
              :error="basicErrors.firstName != null"
              :error-messages="basicErrors.firstName"
          />
          <v-text-field
              label="Last name"
              v-model="basic.lastName"
              :error="basicErrors.lastName != null"
              :error-messages="basicErrors.lastName"
          />
          <v-text-field
              class="mb-3"
              label="Email"
              v-model="basic.email"
              :error="basicErrors.email != null"
              :error-messages="basicErrors.email"
          />
          <v-alert small v-if="basic.emailChange" color="light">
            An email has been sent to <strong>{{ basic.emailChange.email }}</strong> for verification. Please check your
            inbox to validate you own it and complete your email change.
          </v-alert>
          <v-btn :loading="basicLoading" @click="basicAction" color='primary' depressed outlined>
            <v-icon small class="mr-1">mdi-content-save-outline</v-icon>
            Update details
          </v-btn>
        </v-card>
      </v-col>
      <v-col class="d-flex flex-column">
        <h2 class="card-header mb-2">Change password</h2>
        <v-card class="pa-3 flex-fill d-flex flex-column justify-end">
          <v-text-field
              type="password"
              label="Existing password"
              v-model="password.existingPassword"
              :error="passwordErrors.existingPassword != null"
              :error-messages="passwordErrors.existingPassword"
          />
          <v-text-field
              type="password"
              label="New Password"
              v-model="password.password"
              :error="passwordErrors.password != null"
              :error-messages="passwordErrors.password"
          />
          <v-text-field
              type="password"
              class="mb-3"
              label="Confirm new password"
              v-model="password.confirmPassword"
              :error="basicErrors.confirmPassword != null"
              :error-messages="passwordErrors.confirmPassword"
          />
          <v-btn :loading="passwordLoading" @click="passwordAction" color='primary' depressed outlined>
            <v-icon small class="mr-1">mdi-content-save-outline</v-icon>
            Update password
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
    <div class="text-center">
      <v-btn class="mt-7" @click="deleteDialog = true" small depressed>
        <v-icon size="14" class="mr-1">mdi-trash-can</v-icon>
        Delete account
      </v-btn>
    </div>
    <v-dialog
        persistent
        max-width="500"
        v-model="deleteDialog">
      <v-card :loading="deleteLoading">
        <v-card-title class="text-h5">
          Are you sure?
        </v-card-title>
        <v-card-text>Deleting your account is instant. hiTAG cannot guarantee that any lost data caused by deleting your account can be recovered.</v-card-text>
        <v-card-actions v-if="deleteLoading">
          Deleting your account
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="confirmDelete"
          >
            Delete my account
          </v-btn>
          <v-btn
              color="green"
              text
              @click="deleteDialog = false"
          >
            Don't delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </crud-page>
</template>

<script>

import CrudPage from "../../components/CrudPage";
import {mapGetters} from "vuex";
import {endpoints, httpRequest} from "../../store/network/hitag.client";
import router from "@/router";

export default {
  name: 'Dashboard',
  components: {
    CrudPage
  },
  data() {
    return {
      basic: {},
      basicLoading: false,
      basicErrors: {},
      deleteLoading: false,
      deleteErrors: {},
      deleteDialog : false,
      password: {},
      passwordLoading: false,
      passwordErrors: {},
    }
  },
  computed: {
    ...mapGetters('auth', ['user'])
  },
  created() {
  },
  watch: {
    user: {
      immediate: true,
      handler(newVal) {
        this.basic = newVal;
      }
    }
  },
  methods: {
    async passwordAction() {
      this.passwordLoading = true;
      this.passwordErrors = {};
      try {
        let res = await httpRequest(endpoints.auth.mePassword, {
          existingPassword: this.password.existingPassword,
          password: this.password.password,
          confirmPassword: this.password.confirmPassword,
        });
        this.$store.dispatch("auth/me", res.data);
      } catch (e) {
        this.passwordErrors = e.response.data;
      } finally {
        this.passwordLoading = false;
      }
    },
    async basicAction() {
      this.basicLoading = true;
      this.basicErrors = {};
      try {
        let res = await httpRequest(endpoints.auth.meUpdate, {
          email: this.basic.email,
          firstName: this.basic.firstName,
          lastName: this.basic.lastName,
        });
        this.$store.dispatch("auth/me", res.data);
      } catch (e) {
        this.basicErrors = e.response.data;
      } finally {
        this.basicLoading = false;
      }
    },
    async confirmDelete() {
      this.deleteLoading = true;
      this.deleteErrors = {};
      try {
        await httpRequest(endpoints.users.deleteItem(this.user.id),null);
        this.$store.dispatch('auth/logout').then(() => {
          this.$store.dispatch('alerts/setUserMessage','Your account has been deleted and you have been logged out');
        });
      } catch (e) {
        this.deleteErrors = e.response.data;
      } finally {
        this.deleteLoading = false;
      }
    }
  }

}
</script>
