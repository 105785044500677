<template>
    <centered-layout>
        <v-form @submit.prevent="createPassword({password: password, passwordConfirmation: passwordConfirmation, token : token})">
            <div class="pa-5">
                <div v-if="loading && tempUser == null">

                </div>
                <div v-else>
                    <div v-if="tempUser != null">
                        <h2>Hello {{ tempUser.fullName }}</h2>
                        <template v-if="tempUser.emailChange">
                            <div>
                                Your email has been updated to {{ tempUser.email }}
                            </div>

                            <v-btn class="mt-2" :loading="loading"
                                   :to="{name : 'Home'}"
                                   color='success' dark>
                                <v-icon small class="mr-1">mdi-home</v-icon>
                                Back to home
                            </v-btn>

                        </template>
                        <template v-else>
                            <div>
                                {{ tempUser.userId ? "Create a new password below" : "Create a password below to start using hiTAG" }}
                            </div>

                            <div style="font-size:12px;" class="font-weight-bold">
                                (Passwords must be a minimum of 8 characters and contain an upper and lower case letter)
                            </div>

                            <v-text-field
                                    :loading="loading"
                                    :disabled="loading"
                                    v-model="password"
                                    type="password"
                                    label="Create password"
                                    :error="validationError.password != null"
                                    :error-messages="validationError.password"
                            ></v-text-field>

                            <v-text-field
                                    :loading="loading"
                                    :disabled="loading"
                                    type="password"
                                    v-model="passwordConfirmation"
                                    label="Confirm password"
                                    :error="validationError.passwordConfirmation != null"
                                    :error-messages="validationError.passwordConfirmation"
                            ></v-text-field>



                            <global-error-inline :errors="validationError"/>

                            <div class="mb-5" v-if="!tempUser.userId">
                                Please check below to accept our <a @click="eula=true">end user license</a> and <a @click="privacy=true">privacy policy</a>
                            </div>

                            <v-checkbox
                                    v-if="!tempUser.userId"
                                    v-model="terms"
                                    label="I accept the end user license agreement and privacy policy"
                            ></v-checkbox>

                            <v-btn :loading="loading"
                                   :disabled="!tempUser.userId && !terms"
                                   type="submit"
                                   color='success' depressed dark>
                                <v-icon small class="mr-1">mdi-lock-outline</v-icon>
                                {{ tempUser.userId ? "Reset password" : "Create password" }}
                            </v-btn>

                        </template>
                    </div>
                    <div v-else-if="validationError">
                        <global-error-inline :errors="validationError"/>
                    </div>
                </div>
            </div>
        </v-form>
        <v-dialog v-model="eula">
            <v-card>
                <v-card-title class="d-flex light">
                    <h3 class="primary--text flex-fill">HI TAG WEBSITE AND APP – LICENCE AND TERMS OF USE</h3>
                    <v-btn @click="eula = false" icon>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text class="my-3">
                    <terms-of-use :hide-title="true"></terms-of-use>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog scrollable v-model="privacy">
            <v-card>
                <v-card-title class="d-flex light">
                    <h3 class="primary--text flex-fill">Privacy policy</h3>
                    <v-btn @click="privacy = false" icon>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text class="my-3">
                    <privacy-policy :hide-title="true"></privacy-policy>
                </v-card-text>
            </v-card>
        </v-dialog>
    </centered-layout>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import GlobalErrorInline from "../components/GlobalErrorInline";
    import CenteredLayout from "../layouts/CenteredLayout";
    import TermsOfUse from "../components/frontend/TermsOfUse";
    import PrivacyPolicy from "../components/frontend/PrivacyPolicy";

    export default {
        name: 'ValidateEmail',
        props: {
            token: null,
        },
        data: () => ({
            terms: false,
            eula: false,
            privacy: false,
            password: null,
            passwordConfirmation: null,
            rules: [
                value => !!value || 'Required.',
                value => (value && value.length >= 3) || 'Min 3 characters',
            ],
        }),
        created() {
            this.validateToken(this.token);
        },
        computed: {
            ...mapGetters('auth', ['loading', 'tempUser', 'validationError', 'createdUser']),
        },
        watch: {
            createdUser(val) {
                if (val.email === this.tempUser.email) {
                    this.$router.replace({name: 'Login'}).then(() => {
                        this.$store.dispatch('alerts/setUserMessage', "You can now login using your new login credentials");
                    });
                }
            }
        },
        methods: {
            ...mapActions('auth', ['validateToken', 'createPassword'])
        },
        components: {PrivacyPolicy, TermsOfUse, CenteredLayout, GlobalErrorInline},
    }
</script>
