<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <crud-page icon="mdi-gesture" title="Hoses">
        <paginated-result-loader
            :enable-exports="true"
                namespace="hoses">
            <template v-slot:default="props">
                <hose-list :items="props.items"/>
            </template>
        </paginated-result-loader>
    </crud-page>
</template>

<script>

    import CrudPage from "../../../components/CrudPage";
    import {mapGetters} from "vuex";
    import PaginatedResultLoader from "../../../components/PaginatedResultLoader";
    import OrganisationList from "../../../components/organisations/OrganisationList";
    import HoseConfigurationList from "../../../components/hose-configurations/HoseConfigurationList";
    import HoseList from "../../../components/hoses/HoseList";

    export default {
        name: 'Hoses',
        components: {
            HoseList,
            HoseConfigurationList,
            PaginatedResultLoader,
            CrudPage
        },
        computed : {
            ...mapGetters('auth',['clientAdmin']),
        }
    }
</script>
