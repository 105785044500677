<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <form-loader
      :save-disabled="note == null || note === ''"
      :on-save-listeners="['hoseUpdated']"
      class="px-4"
      :form-type="formType"
      :title="formTitle"
      :before-save="beforeSave"
      :item-transform="itemTransform"
      :btn-text="formTitle"
      :id="hoseId"
      v-on:itemUpdated="hoseUpdated"
      :initial-item="{hoseId : hoseId}"
      deleteBtnText="Archive"
      btn-icon="mdi-pencil"
      namespace="hoseMeta">
    <template #default="{item,errors,onUpdate}">

        <v-textarea
            label="Note"
            v-model="note"
            @input="onUpdate(item)"
            :error="errors.notes != null"
            :error-messages="errors.notes"
        />

    </template>
  </form-loader>
</template>

<script>
import FormLoader from "../../../components/FormLoader";
import {mapActions, mapGetters} from "vuex";
import ApiSelectBox from "../../../components/ApiSelectBox";
import HoseConfigurationView from "../../../components/hose-configurations/HoseConfigurationView";
import ItemListLoader from "@/components/ItemListLoader.vue";
import {endpoints} from "@/store/network/hitag.client";

export default {
  name: "HoseNoteEdit",
  components: {ItemListLoader, HoseConfigurationView, ApiSelectBox, FormLoader},
  data() {
    return {
      hose: null,
      note : null
    }
  },
  props: {
    hoseId: null
  },
  computed: {
    ...mapGetters('users', ['item']),
    ...mapGetters('auth', ['user', 'clientAdmin', 'distributor', 'customer', 'organisation', 'optionalFields']),
    formType() {
      if (this.$router.currentRoute.name === "HoseNote") return 'add';
      return "delete";
    },
    formTitle() {
      return this.$router.currentRoute.name === "HoseNote" ? "Add note" : "Archive note";
    }
  },
  methods: {
    hoseUpdated(item) {
      this.hose = item;
    },
    onHoseUpdate(hose) {
      console.log("HOSE UPDATED", hose);
      this.getItem(this.hoseId);
    },
    onHoseLoaded(hose) {
      console.log("HOSE EMITTED ", hose);
    },
    beforeSave(item) {
      item.notes = this.note;
      return item;
    },
    itemTransform(item) {
      return item;
    },
    ...mapActions('hoses', ['getItem'])

  }
}
</script>

<style scoped>

</style>
