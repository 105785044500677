<template>
  <v-simple-table>
    <template>
      <thead>
      <tr>
        <th class="text-left">
          Name
        </th>
        <th v-if="clientAdmin" class="text-left">
          Organisation
        </th>
        <th class="text-left">
          Bore size
        </th>
        <th class="text-left">
          Hose type
        </th>
        <th class="text-left">
          Tagged hoses
        </th>

        <th>

        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="item in items"
          :key="item.id">
        <td>
          <hose-configuration-dropdown :id="item.id"></hose-configuration-dropdown>
          {{ item.name }}
        </td>
        <td v-if="clientAdmin" class="text-left">
          {{ item.organisationName }}
        </td>
        <td>{{ item.boreSize ?? "N/A" }}</td>
        <td>{{ item.type ?? "N/A" }}</td>
        <td>{{ item.hoseCount }}</td>
        <td class="text-right">
          <action-dropdown :items="getDropdown(item)"/>
        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
import {mapGetters} from "vuex";
import ActionDropdown from "../ActionDropdown";
import HoseConfigurationDropdown from "./HoseConfigurationDropdown";

export default {
  name: 'HoseConfigurationList',
  components: {HoseConfigurationDropdown, ActionDropdown},
  props: {
    items: {
      type: Array
    }
  },
  computed: {
    ...mapGetters('auth', ['clientAdmin'])
  },
  methods: {
    getDropdown(item) {
      return [
        {
          label: 'View full details',
          icon: 'mdi-arrow-expand-all',
          route: {name: 'HoseConfigurationView', params: {hoseConfigurationId: item.id}}
        },
        {
          label: 'Edit config',
          icon: 'mdi-pencil',
          route: {name: 'HoseConfigurationEdit', params: {hoseConfigurationId: item.id}}
        },
        {
          label: 'Delete config',
          icon: 'mdi-delete-outline',
          route: {name: 'HoseConfigurationDelete', params: {hoseConfigurationId: item.id}}
        }
      ]
    }
  }
}
</script>
