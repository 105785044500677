<template>
  <crud-page title="Tag management">
    <h2 class="card-header mb-2">Current usage</h2>
    <v-row align="stretch">
      <v-col>
        <ApiCountCard :listeners="['batchUpdated']" :custom-endpoint="{method : 'get', path : 'tags-uploaded-count', requiresAuth: true}" :uuid="random" text="Tags uploaded"
                      classes="warning--text" namespace="hoses"/>
      </v-col>
      <v-col>
        <ApiCountCard :listeners="['batchUpdated']" :custom-endpoint="{method : 'get', path : 'tags-allocated-count', requiresAuth: true}" :uuid="random" text="Tags allocated"
                      classes="success--text" namespace="hoses"/>
      </v-col>
      <v-col>
        <ApiCountCard :listeners="['batchUpdated']" :custom-endpoint="{method : 'get', path : 'tags-uninstalled-count', requiresAuth: true}" :uuid="random" text="Tags uninstalled" :custom-filters="{allocated: '0'}"
                      namespace="hoses"/>
      </v-col>
    </v-row>

    <template v-if="systemAdmin">

      <v-row align="stretch">
        <v-col>
          <h2 class="card-header mb-2">Tag upload</h2>
          <v-card :loading="uploadLoading">
            <div class="pa-3">
              <v-text-field
                  label="Batch name"
                  v-model="batchName"
                  type="text"
              />
              <v-file-input
                  v-model="uploadFile"
                  accept="text/csv"
                  label="Select CSV file for upload"
              ></v-file-input>

              <v-btn :disabled="uploadFile == null" :loading="uploadLoading"
                     @click="() => {processUpload(false)}"
                     color='success' depressed
                     dark>
                Validate and process file
              </v-btn>
              <global-error-inline class="mt-2 mb-0" :errors="uploadErrors"></global-error-inline>
            </div>

          </v-card>
        </v-col>


        <v-col class="d-flex flex-column">
          <h2 class="card-header mb-2">Tag deletion</h2>
          <v-card :loading="deleteLoading" class="flex-fill">
            <div class="pa-3">
              <v-file-input
                  v-model="deleteFile"
                  accept="text/csv"
                  label="Select CSV file for upload"
              ></v-file-input>
              <v-btn :disabled="deleteFile == null" :loading="deleteLoading" @click="() => {processUpload(true)}"
                     color='primary' depressed
                     dark>
                Validate and process file
              </v-btn>
              <global-error-inline class="mt-2 mb-0" :errors="deleteErrors"></global-error-inline>
            </div>
          </v-card>
        </v-col>

      </v-row>

      <v-row>
        <v-col>
          <h2 class="card-header mb-1">
            Batch uploads
          </h2>
          <paginated-result-loader
              :listeners="['batchUpdated']"
              :limit="7"
              :disable-filters="true"
              namespace="batches">
            <template v-slot:default="props">
              <tag-batch-list :items="props.items"/>
            </template>
          </paginated-result-loader>
        </v-col>
      </v-row>
    </template>

  </crud-page>
</template>

<script>

import {FileUploadService} from 'v-file-upload'
import CrudPage from "../../../components/CrudPage";
import ApiCountCard from "../ApiCountCard";
import {endpoints} from "@/store/network/hitag.client";
import GlobalErrorInline from "../../../components/GlobalErrorInline";
import PaginatedResultLoader from "@/components/PaginatedResultLoader";
import {mapActions, mapGetters} from "vuex";
import TagBatchList from "@/components/tags/TagBatchList.vue";
import emitter from "@/utils/emitter";

export default {
  name: 'TagManagement',
  components: {
    TagBatchList,
    PaginatedResultLoader,
    GlobalErrorInline,
    ApiCountCard,
    CrudPage
  },
  created() {
    emitter.on('batchUpdated', this.batchUpdated);
  },
  beforeDestroy() {
    emitter.off('batchUpdated', this.batchUpdated);
  },
  data() {
    return {
      uploadLoading: false,
      deleteLoading: false,
      uploadFile: null,
      deleteFile: null,
      uploadErrors: {},
      deleteErrors: {},
      random: 0,
      maxChecks: 5,
      batchName: null
    }
  },
  computed : {
    ...mapGetters('auth', ['systemAdmin'])
  },
  methods: {
    batchUpdated() {
      this.$router.replace({name: 'TagManagement'}).catch(() => {});
    },
    processUpload(isDelete) {
      if (isDelete) {
        this.deleteLoading = true;
        this.deleteErrors = {};
      } else {
        this.uploadLoading = true;
        this.uploadErrors = {};
      }
      var accessToken = localStorage.getItem('accessToken');
      var headers = {Authorization: 'Bearer ' + accessToken, maxChecks: this.maxChecks, batchName: this.batchName};
      console.log("HEADERS", headers);
      let fileUpload = new FileUploadService(
          process.env.VUE_APP_ENDPOINT + (isDelete ? endpoints.tags.delete.path : endpoints.tags.upload.path),
          headers,
          this.onUploadProgress
      );
      fileUpload
          .upload((isDelete ? this.deleteFile : this.uploadFile))
          .then(response => {
            if (response.currentTarget.response.count) {
              this.$store.dispatch('alerts/setUserMessage', isDelete ? ("Removed " + response.currentTarget.response.count + " tags from the system") : ("Added " + response.currentTarget.response.count + " tags to the system"));
            }
            this.deleteFile = null;
            this.uploadFile = null;
          })
          .catch(e => {
            console.log("ERROR IS", e);
            // Handle error
            if (isDelete) {
              this.deleteErrors = e.currentTarget.response;
            } else {
              this.uploadErrors = e.currentTarget.response;
            }
          }).finally(() => {
        if (isDelete) {
          this.deleteLoading = false;
        } else {
          this.uploadLoading = false;
        }
        emitter.emit('batchUpdated');
      });
    },
    onUploadProgress(event, isDelete) {
      // Handle the progress
      console.log("PROGREESS", event);
    },
  }
}
</script>
