<template>
  <div>
    <v-dialog
        persistent
        max-width="500"
        v-model="deleteDialog">
      <v-card :loading="saveLoading">
        <v-card-title class="text-h5">
          Are you sure?
        </v-card-title>
        <v-card-text>
          <div>Archiving this organisation will remove access for all users using it.</div>
          <global-error-inline :errors="saveError"></global-error-inline>
        </v-card-text>
        <v-card-actions v-if="saveLoading">
          Archiving {{ selected.name }}
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="confirmArchive"
          >
            Archive {{ selected.name }}
          </v-btn>
          <v-btn
              color="green"
              text
              @click="deleteDialog = false"
          >
            Don't archive
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-simple-table>
      <template>
        <thead>
        <tr>
          <th class="text-left">
            Name
          </th>
          <th class="text-left">
            Account code
          </th>
          <th v-if="full" class="text-left">
            Tagged hoses
          </th>
          <th v-if="clientAdmin && full" class="text-left">
            Organisation type
          </th>
          <th>

          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="item in items"
            :key="item.id"
        >
          <td>{{ item.name }} <v-chip small class="ml-2" color="error" v-if="distributor && item.distributorConfigurationsVisible != null && item.distributorConfigurationsVisible === false">Configs hidden</v-chip></td>
          <td>{{ item.accountCode }}</td>
          <td v-if="full">{{ item.hoseCount }}</td>
          <td v-if="clientAdmin && full">{{ item.isDistributor ? "Distributor" : "Customer" }}</td>
          <td class="text-right">
            <v-progress-circular
                v-if="loadingRows[item.id]"
                indeterminate
                color="primary"/>
            <action-dropdown :items="getDropdown(item)"/>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import ActionDropdown from "../ActionDropdown";
import GlobalErrorInline from "@/components/GlobalErrorInline";
import {endpoints, httpRequest} from "@/store/network/hitag.client";
import emitter from "@/utils/emitter";

export default {
  name: 'OrganisationList',
  components: {GlobalErrorInline, ActionDropdown},
  data() {
    return {
      deleteDialog : false,
      selected : {},
      loadingRows : {},
      errorsRows : {}
    }
  },
  props: {
    items: {
      type: Array
    },
    full: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    ...mapGetters('auth', ['clientAdmin','organisation','distributor']),
    ...mapGetters('organisations', ['saveError','saveLoading']),
  },
  methods: {
    ...mapActions('organisations',['deleteItem','patchItem']),
    async showHideConfigs(item,show) {
      this.loadingRows[item.id] = true;
      this.errorsRows[item.id] = null;
      try {
        let res = await httpRequest(endpoints.organisations.hideShowConfigs(item.id,show), {});
        emitter.emit('organisationUpdated',res.data);
      } catch (e) {
        console.log("error", e);
        console.log("error data", e.response.data);
        this.errorsRows[item.id] = e.response.data;
      } finally {
        this.loadingRows[item.id] = false;
      }

    },
    async confirmArchive() {
      await this.deleteItem(this.selected.id);
      this.deleteDialog = false;
    },
    filtersString(data) {
      return encodeURI(JSON.stringify(data));
    },
    getDropdown(item) {
      var data = [
        {
          label: 'View full details',
          icon: 'mdi-arrow-expand-all',
          route: {name: 'OrganisationView', params: {organisationId: item.id}}
        },
      ];
      if (this.clientAdmin) {
        data.push({
          label: 'View users',
          icon: 'mdi-account-group-outline',
          route: {
            name: 'Users',
            query: {filters: this.filtersString([{key: 'organisationId', value: item.id}])}
          }
        });
      }
      data.push({
        label: 'View hoses',
        icon: 'mdi-gesture',
        route: {
          name: 'Hoses',
          query: {
            filters: this.filtersString([{
              key: (item.isDistributor ? 'distributorOrganisationId' : 'customerOrganisationId'),
              value: item.id
            }])
          }
        }
      });
      data.push({
        label: 'View inspections',
        icon: 'mdi-clipboard-list-outline',
        route: {
          name: 'Inspections',
          query: {
            filters: this.filtersString([{
              key: (item.isDistributor ? 'distributorOrganisationId' : 'customerOrganisationId'),
              value: item.id
            }])
          }
        }
      });
      data.push({
        label: 'View quotes',
        icon: 'mdi-clipboard-check-outline',
        route: {
          name: 'Quotes',
          query: {
            filters: this.filtersString([{
              key: (item.isDistributor ? 'distributorOrganisationId' : 'customerOrganisationId'),
              value: item.id
            }])
          }
        }
      });
      if(this.distributor) {
        data.push({
          label : 'Unlink customer',
          icon : 'mdi-account-minus',
          route : {name : 'OrganisationLink', params : {accountCode : item.accountCode}}
        })
        if(item.distributorConfigurationsVisible) {
          data.push({
            label: 'Hide configs',
            icon: 'mdi-trash-can',
            click: () => {
              this.showHideConfigs(item,false);
            }
          });
        } else {
          data.push({
            label: 'Show configs',
            icon: 'mdi-refresh',
            click: () => {
              this.showHideConfigs(item,true);
            }
          });
        }
      }
      if (this.clientAdmin) {
        data.push({
          label: 'Edit organisation',
          icon: 'mdi-pencil',
          route: {name: 'OrganisationEdit', params: {organisationId: item.id}}
        });
        if (item.deletedAt == null) {
          data.push({
            label: 'Archive organisation',
            icon: 'mdi-trash-can',
            click: () => {
              this.selected = item;
              this.deleteDialog = true;
            }
          });
        } else {
          data.push({
            label: 'Unarchive organisation',
            icon: 'mdi-refresh',
            click: () => {
              this.patchItem(item.id);
            }
          });
        }
      }
      return data;
    }
  }
}
</script>
