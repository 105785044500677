import {httpRequest, endpoints} from "../network/hitag.client";
import router from '../../router/index.js';

const currentUser = () => {
    try {
        return JSON.parse(localStorage.getItem('user'));
    } catch (e) {
        return null;
    }
}

const alertsModule = {
    namespaced: true,
    state: () => ({
        userMessage : null,
    }),
    getters: {
        userMessage: state => state.userMessage,
    },
    mutations: {
        userMessage(state, data) {
            state.userMessage = data;
        },
    },
    actions: {
        setUserMessage(context,data) {
            context.commit('userMessage',data);
        }
    }
};
export default alertsModule;
