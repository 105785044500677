<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <crud-page :actions="actions" :title="'Sites & zones'">

    <div class="mb-4">
      Here you can manage your various sites & zones for your organisation
    </div>

    <api-select-box
        class="mb-4"
        v-if="clientAdmin || distributor"
        :custom-filters="[{key : 'isDistributor', value : false}]"
        label="Select customer"
        hint="Select customer to manage sites and zones for"
        item-text="name"
        item-value="id"
        v-model="organisationId"
        namespace="organisationSelect"
    />

    <paginated-result-loader
        :hide-card="true"
        :custom-filters="[{key : 'organisationId', value : organisationId}]"
        v-if="organisationId != null"
        disable-filters="true"
        namespace="sites">
      <template v-slot:default="props">
        <site-list :items="props.items"></site-list>
      </template>
    </paginated-result-loader>
  </crud-page>
</template>

<script>

import CrudPage from "../../../components/CrudPage";
import {mapGetters} from "vuex";
import PaginatedResultLoader from "../../../components/PaginatedResultLoader";
import OrganisationList from "../../../components/organisations/OrganisationList";
import ApiSelectBox from "../../../components/ApiSelectBox";
import SiteList from "../../../components/sites/SiteList";
import emitter from "@/utils/emitter";

export default {
  name: 'Sites',
  components: {
    SiteList,
    ApiSelectBox,
    OrganisationList,
    PaginatedResultLoader,
    CrudPage
  },
  data() {
    return {
      organisationId: null
    }
  },
  created() {
    if (this.customer) {
      this.organisationId = this.organisation.id
    }
    emitter.on('siteUpdated', this.siteUpdated);
  },
  beforeDestroy() {
    emitter.off('siteUpdated');
  },
  methods : {
    async siteUpdated() {
      await this.$store.dispatch('sites/getItems', {});
      await this.$router.replace({name: 'SitesAndZones'}).catch(() => {
      });
    }
  },
  computed: {
    ...mapGetters('auth', ['clientAdmin', 'distributor', 'customer', 'organisation', 'isAdmin']),
    actions() {
      var list = [];
      if (this.organisationId != null && this.isAdmin) {
        list.push({
          icon: 'mdi-plus-circle-outline',
          title: 'Create site/zone',
          route: {name: 'SiteAdd', params: {organisationId: this.organisationId}},
          variant: 'success'
        });
      }
      return list;
    }
  }
}
</script>
