<template>
    <div style="display: inline-block" v-if="status != null"
         class="d-inline-block  pa-2 rounded light flex-row ">
        <div class="d-flex align-center">
            <v-avatar
                    class="mr-2 flex-wrap"
                    :color="$options.filters.statusToColor(status)"
                    size="16"
            />
            <strong class="flex-wrap">{{ $options.filters.statusToString(status) }}</strong>
        </div>
    </div>
</template>

<script>
    export default {
        name: "HoseStatus",
        props: {
            status: null,
        },
        computed: {}
    }
</script>

<style scoped>

</style>
