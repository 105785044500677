<template>
  <component v-bind="$attrs" :is="component">
    <slot name="filters" v-bind:loading="loading"
          v-bind:pagination="pagination"
          v-bind:items="items"
          v-bind:filters="filters">

    </slot>
    <slot v-if="loading" name="loader">
      <v-progress-circular v-if="items.length === 0" :indeterminate="true" color="primary"></v-progress-circular>
      <v-progress-linear v-else :indeterminate="true" color="primary"></v-progress-linear>
    </slot>
    <slot name="before">
    </slot>
    <slot name="main" v-if="this.items.length > 0 || disableNoResults"
          v-bind:loading="loading"
          v-bind:pagination="pagination"
          v-bind:items="items"
          v-bind:filters="filters" v-bind:onUpdate="onUpdate">

    </slot>
    <slot v-if="hasErrors" name="errors">
      <v-alert color="error" class="white--text">
        There was an error loading your data
      </v-alert>
    </slot>
    <slot v-if="noResultsData && !disableNoResults" name="noResults">
      {{ noResults }}
    </slot>
    <slot name="after" v-bind:loading="loading"
          v-bind:pagination="pagination"
          v-bind:errors="errors"
          v-bind:items="items"
          v-bind:filters="filters">

    </slot>
  </component>
</template>

<script>
import GlobalErrorInline from "./GlobalErrorInline";
import {endpoints, httpRequest} from "@/store/network/hitag.client";
import _ from "lodash";
import emitter from "@/utils/emitter.js";

export default {
  name: "ItemListLoader",
  components: {GlobalErrorInline},
  emits: ['itemsUpdated'],
  props: {
    debounced : false,
    disableNoResults : false,
    listeners: {
      type: Array,
      default() {
        return [];
      }
    },
    component: {
      type: String,
      default() {
        return "div"
      }
    },
    resetId: {
      type: String,
      default() {
        return ""
      }
    },
    queryParams: {
      type: Object,
      default() {
        return {}
      }
    },
    customFilters: {
      type: Object,
      default() {
        return {}
      }
    },
    namespace: null,
    customEndpoint: null,
    limit: {
      type: Number,
      default() {
        return 10
      }
    },
    page: {
      type: Number,
      default() {
        return 1
      }
    },
    noResults: {
      type: String,
      default() {
        return "No results for your filter"
      }
    },
    itemsTransform: {
      type: Function,
      default: null
    },
    itemTransform: {
      type: Function,
      default: null
    },
    onNewData: {
      type: Function,
      default: null
    },
    initialItems: {
      type: Array,
      default() {
        return []
      }
    },
  },
  data: () => ({
    errors: {},
    items: [],
    loading: false,
    filters : [],
    pagination : {},
    debouncedUpdate : null,
  }),
  created() {
    this.items = this.initialItems
    this.debouncedUpdate = _.debounce(this.getItems, 700);
    this.getItems();
    this.listeners.forEach(listenerKey => {
      emitter.on(listenerKey, () => {
        this.getItems();
      });
    })
  },
  beforeDestroy() {
    this.listeners.forEach(listenerKey => {
      emitter.off(listenerKey);
    });
  },
  watch: {
    resetId(newVal, oldVal) {
      if (newVal != null) this.getItems();
    },
    items(newVal, oldVal) {
      if (this.itemTransform != null) {
        var newList = [];
        _.forEach(newVal, (item) => {
          newList.push(this.itemTransform(newVal));
        })
        this.items = newList;
      }
      this.$emit("itemsUpdated", this.items);
    },
    customFilters: {
      deep: true,
      handler: function (newVal, oldVal) {
        if(_.isEqual(newVal,oldVal)) return;
        if(this.debounced){
          this.debouncedUpdate();
        } else {
            this.getItems();
        }
      }
    },
    customEndpoint(newVal, oldVal) {
      if (newVal === oldVal) return;
      this.getItems();
    },
    page(newVal, oldVal) {
      if (newVal === oldVal) return;
      console.log("NEW PAGE");
      this.getItems();
    },
    saveError(newVal, oldVal) {
      console.log("NEW ERRORS");
      this.errors = newVal;
    },
  },
  computed: {
    hasErrors() {
      return Object.keys(this.errors).length > 0
    },
    noResultsData() {
      return !this.loading && this.items.length === 0 && !this.hasErrors;
    }
  },
  methods: {
    async getItems() {
      this.loading = true;
      try {
        var finalEndpoint = this.customEndpoint != null ? this.customEndpoint : endpoints[this.namespace].getItems;
        console.log("ITEM_LIST_LOADER   " + finalEndpoint.path);
        let res = await httpRequest(finalEndpoint, {page: this.page, limit: this.limit, ...this.queryParams, filters: this.customFilters});

        this.items = res.data.items ?? res.data;
        this.filters = res.data.filters ?? [];
        this.pagination = res.data.totalPages ? {
          totalPages : res.data.totalPages,
          totalItems : res.data.totalItems,
          currentPage : res.data.currentPage,
          limit: this.limit
        } : {};
      } catch (e) {
        console.log("error", e);
        console.log("error data", e.response.data);
        this.errors = e.response.data;
      } finally {
        this.loading = false;
        if (this.itemsTransform != null) {
          this.items = this.itemTransform(this.items);
        }
        if(this.onNewData != null) {
          this.onNewData(this.items,this.filters,this.errors,this.pagination);
        }
      }
    },
    onUpdate(item) {
      this.items = item;
      this.$emit("itemsUpdated", item);
    },
  },
}
</script>
