<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <crud-page icon="mdi-cog-outline" :actions="actions" title="Configuration library">
    <paginated-result-loader
        :enable-exports="true"
        namespace="hoseConfigurations">
      <template v-slot:default="props">
        <hose-configuration-list :items="props.items"/>
      </template>
    </paginated-result-loader>
  </crud-page>
</template>

<script>

import CrudPage from "../../../components/CrudPage";
import {mapActions, mapGetters} from "vuex";
import PaginatedResultLoader from "../../../components/PaginatedResultLoader";
import OrganisationList from "../../../components/organisations/OrganisationList";
import HoseConfigurationList from "../../../components/hose-configurations/HoseConfigurationList";
import emitter from "@/utils/emitter";

export default {
  name: 'HoseConfigurations',
  components: {
    HoseConfigurationList,
    PaginatedResultLoader,
    CrudPage
  },
  created() {
    emitter.on('hoseConfigurationUpdated', this.hoseConfigurationUpdated);
  },
  beforeDestroy() {
    emitter.off('hoseConfigurationUpdated');
  },
  methods : {
    ...mapActions('hoseConfigurations', ['getItems']),
    async hoseConfigurationUpdated() {
      console.log("GERER");
      await this.getItems({});
      await this.$router.replace({name: 'HoseConfigurations'}).catch(() => {
      });
    }
  },
  computed: {
    ...mapGetters('auth', ['clientAdmin']),
    actions() {
      var list = [];
      list.push({
        icon: 'mdi-plus-circle-outline',
        title: 'Import hose configs',
        route: {name: 'HoseConfigurationsImport'},
        variant: 'info'
      });
      list.push({
        icon: 'mdi-plus-circle-outline',
        title: 'Create hose config',
        route: {name: 'HoseConfigurationAdd'},
        variant: 'success'
      });

      return list;
    }
  }
}
</script>
