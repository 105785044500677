<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-simple-table>
        <template v-slot:default>
            <thead>
            <tr>
                <th>
                    Access
                </th>
                <th class="text-center">
                    User
                </th>
                <th class="text-center">
                    Manager
                </th>
                <th class="text-center">
                    Admin
                </th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="distributor">
                <td>Customers</td>
                <td class="text-center">
                    <v-icon color="success">mdi-check</v-icon>
                </td>
                <td class="text-center">
                    <v-icon color="success">mdi-check</v-icon>
                </td>
                <td class="text-center">
                    <v-icon color="success">mdi-check</v-icon>
                </td>
            </tr>
            <tr v-if="customer">
                <td>Sites & zones</td>
                <td class="text-center">
                    <v-icon color="gray">mdi-eye</v-icon>
                </td>
                <td class="text-center">
                    <v-icon color="gray">mdi-eye</v-icon>
                </td>
                <td class="text-center">
                    <v-icon color="success">mdi-check</v-icon>
                </td>
            </tr>
            <tr>
                <td>Tagged hoses</td>
                <td class="text-center">
                    <v-icon color="success">mdi-check</v-icon>
                </td>
                <td class="text-center">
                    <v-icon color="success">mdi-check</v-icon>
                </td>
                <td class="text-center">
                    <v-icon color="success">mdi-check</v-icon>
                </td>
            </tr>
            <tr>
                <td>Configuration catalogue</td>
                <td class="text-center">
                    <v-icon color="success">mdi-check</v-icon>
                </td>
                <td class="text-center">
                    <v-icon color="success">mdi-check</v-icon>
                </td>
                <td class="text-center">
                    <v-icon color="success">mdi-check</v-icon>
                </td>
            </tr>
            <tr>
                <td>Inspections</td>
                <td class="text-center">
                    <v-icon color="success">mdi-check</v-icon>
                </td>
                <td class="text-center">
                    <v-icon color="success">mdi-check</v-icon>
                </td>
                <td class="text-center">
                    <v-icon color="success">mdi-check</v-icon>
                </td>
            </tr>

            <tr>
                <td>Quote requests</td>
                <td class="text-center">
                    <v-icon color="success">mdi-check</v-icon>
                </td>
                <td class="text-center">
                    <v-icon color="success">mdi-check</v-icon>
                </td>
                <td class="text-center">
                    <v-icon color="success">mdi-check</v-icon>
                </td>
            </tr>
            <tr>
                <td>Users</td>
                <td class="text-center">
                    <v-icon color="primary">mdi-cancel</v-icon>
                </td>
                <td class="text-center">
                    <v-icon color="success">mdi-check</v-icon>
                </td>
                <td class="text-center">
                    <v-icon color="success">mdi-check</v-icon>
                </td>
            </tr>
            <tr>
              <td>Data export</td>
              <td class="text-center">
                <v-icon color="primary">mdi-cancel</v-icon>
              </td>
              <td class="text-center">
                <v-icon color="primary">mdi-cancel</v-icon>
              </td>
              <td class="text-center">
                <v-icon color="success">mdi-check</v-icon>
              </td>
            </tr>
            <tr>
                <td>Organisation settings</td>
                <td class="text-center">
                    <v-icon color="primary">mdi-cancel</v-icon>
                </td>
                <td class="text-center">
                    <v-icon color="primary">mdi-cancel</v-icon>
                </td>
                <td class="text-center">
                    <v-icon color="success">mdi-check</v-icon>
                </td>
            </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "UserRoles",
        computed: {
            ...mapGetters('auth', ['distributor', 'customer'])
        }
    }
</script>

<style scoped>

</style>
