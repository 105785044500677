<template>
    <div v-if="value" class="d-flex flex-row justify-space-between align-center py-1">
        <label class="mr-2">{{ label }}</label>
        <div class="val">{{ value }}</div>
    </div>
</template>
<script>
    export default {
        name: 'LabelValueHorizontal',
        props: {
            label : null,
            value : null
        }
    }
</script>
<style scoped lang="scss">
    .d-flex {
        border-bottom: solid 1px #eeeeee;
    }
    label {
        color: #111111;
        font-weight: 700;
        font-size: 0.875rem;
    }

    .col {
        padding-bottom: 0;
        padding-top: 0;
    }
    .val {
        font-size: 0.875rem;
    }
</style>
