<template>
    <div>
        <div class="pa-3 site-item rounded mb-3"
                v-for="item in items"
                :key="item.id"
        >
            <div class="d-flex align-center flex-row justify-space-between">
                <strong class="text-lg-h6">{{ item.name }}</strong>
                <action-dropdown v-if="isAdmin" class="ma-0" :items="getDropdown(item)"/>
            </div>
            <div v-if="item.children != null && item.children.length > 0"  class="ml-3 mt-3">
            <site-list :items="item.children"></site-list>
            </div>
        </div>
    </div>
</template>
<script>
    import {mapGetters} from "vuex";
    import ActionDropdown from "../ActionDropdown";

    export default {
        name: 'SiteList',
        components: {ActionDropdown},
        props: {
            items: {
                type: Array
            }
        },
        computed: {
            ...mapGetters('auth', ['clientAdmin','isAdmin'])
        },
        methods: {
            getDropdown(item) {
                return [
                    {
                        label: 'Add sub-zone',
                        icon: 'mdi-plus-circle-outline',
                        route: {name: 'SiteAdd', params: {organisationId: item.organisationId,parentId: item.id}}
                    },
                    {
                        label: 'Edit zone',
                        icon: 'mdi-pencil',
                        route: {name: 'SiteEdit', params: {siteId: item.id}}
                    },
                    {
                        label: 'Delete zone',
                        icon: 'mdi-delete-outline',
                        route: {name: 'SiteDelete', params: {siteId: item.id}}
                    }
                ]
            }
        }
    }
</script>

<style scoped lang="scss">
    .site-item {
        background: var(--v-white-base);
        border: solid 1px var(--v-border-base);
    }
</style>

