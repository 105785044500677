<template>
  <centered-layout>
    <v-progress-circular :indeterminate="true" color="primary" v-if="loading"></v-progress-circular>
    <GlobalErrorInline :errors="errors"/>
  </centered-layout>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import GlobalErrorInline from "../components/GlobalErrorInline";
import CenteredLayout from "../layouts/CenteredLayout";
import TermsOfUse from "../components/frontend/TermsOfUse";
import PrivacyPolicy from "../components/frontend/PrivacyPolicy";
import {endpoints, httpRequest} from "@/store/network/hitag.client";

export default {
  name: 'OneTimeLogin',
  props: {
    token: null,
  },
  data: () => ({
    loading: false,
    errors: {},
  }),
  created() {
    this.validateToken(this.token);
  },
  computed: {},
  watch: {},
  methods: {
    async validateToken(token) {
      try {
        let res = await httpRequest(endpoints.clientUsers.oneTimeToken(token), {});
        await localStorage.setItem('accessToken', res.data.accessToken);
        await this.$store.dispatch('auth/me');
        window.location = "/select-organisation";
      } catch (e) {
        console.log(e);
        this.errors = {'global' :['Invalid request']};
      }
    }
  },
  components: {PrivacyPolicy, TermsOfUse, CenteredLayout, GlobalErrorInline},
}
</script>
