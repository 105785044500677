<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <crud-page icon="mdi-view-dashboard-outline" title="Dashboard">
    <template v-if="systemAdmin && !clientAdmin && !customer && !distributor">
      <v-alert type="info" outlined>
        <div class="d-flex align-center justify-space-between">
          <span>You are a system admin, but not a client admin of this system. To access data from this client please login as an admin for this client.</span>
          <v-btn color="primary" small :to="{name : 'ClientUsers', params :{clientId : currentClient.id}}">Login as admin</v-btn>
        </div>
      </v-alert>
    </template>

    <template v-else>

      <template v-if="clientAdmin">
      <item-list-loader
          namespace="organisations"
          :custom-filters="{isDistributor: true}"
          :limit="1000"
      >
        <template v-slot:main="orgSlot">
          <v-autocomplete
              class="mb-3"
              solo
              :clearable="true"
              :hide-details="true"
              v-model="selectedOrgs"
              :chips="true"
              :multiple="true"
              :items="orgSlot.items"
              item-text="name"
              item-value="id"
              :label="selectedOrgs.length > 0 ? 'Showing ' + selectedOrgs.length + ' selected' : 'Select Distributors'"
              placeholder="Showing all"
          ></v-autocomplete>
        </template>

      </item-list-loader>
      </template>

      <v-row align="stretch">
        <template v-if="clientAdmin">
          <v-col>
            <ApiCountCard :custom-filters="addSelectedOrgs({isDistributor:'true'},'organisationId')"
                          :to="{name:'Organisations', query: { filters : filtersString([{key : 'isDistributor', value : 'true'}])}}"
                          text="Distributors"
                          classes=""
                          namespace="organisations"/>
          </v-col>
          <v-col>
            <ApiCountCard :custom-filters="addSelectedOrgs({isDistributor:'false'},'distributorId')"
                          :to="{name:'Organisations', query: { filters : filtersString([{key : 'isDistributor', value : 'false'}])}}"
                          text="Customers"
                          classes=""
                          namespace="organisations"/>
          </v-col>
          <v-col>
            <ApiCountCard :to="{name:'Hoses'}" text="Tagged hoses"
                          :custom-filters="addSelectedOrgs({},'distributorOrganisationId')"
                          classes="" namespace="hoses"/>
          </v-col>
        </template>
        <template v-else-if="customer">
          <v-col>
            <ApiCountCard :to="{name:'Hoses'}" text="Tagged hoses"
                          classes="" namespace="hoses"/>
          </v-col>
          <v-col>
            <ApiCountCard
                :to="{name:'Hoses', query: { filters : filtersString([{key : 'inspectionRequired', value : '2'}])}}"
                text="Hoses due inspection" :custom-filters="{inspectionRequired: '2'}"
                classes="info white--text" namespace="hoses"/>
          </v-col>
          <v-col>
            <ApiCountCard :to="{name:'Hoses', query: { filters : filtersString([{key : 'status', value : '1'}])}}"
                          text="Hoses to monitor" :custom-filters="{status: '1'}" classes="warning white--text"
                          namespace="hoses"/>
          </v-col>
          <v-col>
            <ApiCountCard :to="{name:'Hoses', query: { filters : filtersString([{key : 'status', value : '2'}])}}"
                          text="Hoses to replace" :custom-filters="{status: '2'}" classes="error white--text"
                          namespace="hoses"/>
          </v-col>
        </template>
        <template v-else>
          <v-col>
            <ApiCountCard :custom-filters="clientAdmin ? {} : {excludeId: organisation.id}"
                          :to="{name:'Organisations'}" text="Customers"
                          classes=""
                          namespace="organisations"/>
          </v-col>
          <v-col>
            <ApiCountCard :to="{name:'Hoses'}" text="Tagged hoses"
                          classes="" namespace="hoses"/>
          </v-col>
          <v-col>
            <ApiCountCard
                :to="{name:'Hoses', query: { filters : filtersString([{key : 'inspectionRequired', value : '2'}])}}"
                text="Hoses due inspection" :custom-filters="{inspectionRequired: '2'}"
                classes="info white--text" namespace="hoses"/>
          </v-col>
          <v-col>
            <ApiCountCard :to="{name:'Hoses', query: { filters : filtersString([{key : 'status', value : '1'}])}}"
                          text="Hoses to monitor" :custom-filters="{status: '1'}" classes="warning white--text"
                          namespace="hoses"/>
          </v-col>
          <v-col>
            <ApiCountCard :to="{name:'Hoses', query: { filters : filtersString([{key : 'status', value : '2'}])}}"
                          text="Hoses to replace" :custom-filters="{status: '2'}" classes="error white--text"
                          namespace="hoses"/>
          </v-col>
        </template>
      </v-row>
      <v-row v-if="clientAdmin" >
        <v-col>
          <ApiCountCard
              text="Hoses due inspection" :custom-filters="addSelectedOrgs({inspectionRequired: '2'})"
              classes="info white--text" namespace="hoses"/>
        </v-col>
        <v-col>
          <ApiCountCard
                        text="Hoses to monitor" :custom-filters="addSelectedOrgs({status: '1' })" classes="warning white--text"
                        namespace="hoses"/>
        </v-col>
        <v-col>
          <ApiCountCard
                        text="Hoses to replace" :custom-filters="addSelectedOrgs({status: '2'})" classes="error white--text"
                        namespace="hoses"/>
        </v-col>
      </v-row>

      <v-row align="stretch">
        <template v-if="clientAdmin">
          <v-col cols="12" :lg="6" class="d-flex flex-column">
            <div class="d-flex flex-row">
              <h2 class="card-header mb-2 flex-fill">Distributors</h2>
              <v-btn depressed color="white" :to="{name : 'Organisations'}" small>See all</v-btn>
            </div>
            <paginated-result-loader
                cardHeight="450"
                :custom-filters="addSelectedOrgsArray([{key: 'isDistributor',value: 'true'}],'organisationId')"
                :disable-filters="true"
                namespace="organisationsDashboard">
              <template v-slot:default="props">
                <organisation-list :full="false" :items="props.items"/>
              </template>
            </paginated-result-loader>
          </v-col>
          <v-col cols="12" :lg="6" v-if="!customer" class="d-flex flex-column">
            <div class="d-flex flex-row">
              <h2 class="card-header mb-2 flex-fill">Customers</h2>
              <v-btn depressed color="white" :to="{name : 'Organisations'}" small>See all</v-btn>
            </div>
            <paginated-result-loader
                cardHeight="450"
                :custom-filters="addSelectedOrgsArray([{key: 'isDistributor',value: 'false'}],'distributorId')"
                :disable-filters="true"
                namespace="organisationsDashboard2">
              <template v-slot:default="props">
                <organisation-list :full="false" :items="props.items"/>
              </template>
            </paginated-result-loader>
          </v-col>
        </template>
        <template v-else-if="customer">
          <v-col cols="12" :lg="customer ? 12 : 6" class="d-flex flex-column">
            <div class="d-flex flex-row">
              <h2 class="card-header mb-2 flex-fill">Hoses to replace</h2>
              <v-btn depressed color="white"
                     :to="{name:'Hoses', query: { filters : filtersString([{key : 'status', value : '2'}])}}" small>See
                all
              </v-btn>
            </div>
            <paginated-result-loader
                cardHeight="450"
                :disable-filters="true"
                :custom-filters="[{key: 'status',value: '2'}]"
                namespace="hosesDashboard">
              <template v-slot:default="props">
                <hose-list :full="customer ? true : false" :items="props.items"/>
              </template>
            </paginated-result-loader>
          </v-col>
          <v-col cols="12" :lg="6" class="d-flex flex-column">
            <div class="d-flex flex-row">
              <h2 class="card-header mb-2 flex-fill">Latest inspections</h2>
              <v-btn depressed color="white" :to="{name : 'Inspections'}" small>See all</v-btn>
            </div>
            <paginated-result-loader
                cardHeight="450"
                :disable-filters="true"
                namespace="inspectionsDashboard">
              <template v-slot:default="props">
                <inspection-list :full="false" :items="props.items"/>
              </template>
            </paginated-result-loader>
          </v-col>
          <v-col cols="12" :lg="6" class="d-flex flex-column">
            <div class="d-flex flex-row">
              <h2 class="card-header mb-2 flex-fill">Latest quotes</h2>
              <v-btn depressed color="white" :to="{name : 'Quotes'}" small>See all</v-btn>
            </div>
            <paginated-result-loader
                cardHeight="450"
                :disable-filters="true"
                namespace="quotesDashboard">

              <template v-slot:default="props">
                <quote-list :full="false" :items="props.items"/>
              </template>
            </paginated-result-loader>
          </v-col>
        </template>
        <template v-else>
          <v-col cols="12" :lg="6" class="d-flex flex-column">
            <div class="d-flex flex-row">
              <h2 class="card-header mb-2 flex-fill">Customers</h2>
              <v-btn depressed color="white" :to="{name : 'Organisations'}" small>See all</v-btn>
            </div>
            <paginated-result-loader
                cardHeight="450"
                :custom-filters="clientAdmin ? [] : [{key: 'excludeId',value: organisation.id}]"
                :disable-filters="true"
                namespace="organisationsDashboard">
              <template v-slot:default="props">
                <organisation-list :items="props.items"/>
              </template>
            </paginated-result-loader>
          </v-col>
          <v-col cols="12" :lg="customer ? 12 : 6" class="d-flex flex-column">
            <div class="d-flex flex-row">
              <h2 class="card-header mb-2 flex-fill">Hoses to replace</h2>
              <v-btn depressed color="white" :to="{name : 'Hoses'}" small>See all</v-btn>
            </div>
            <paginated-result-loader
                cardHeight="450"
                :disable-filters="true"
                :custom-filters="[{key: 'status',value: '2'}]"
                namespace="hosesDashboard">
              <template v-slot:default="props">
                <hose-list :full="customer ? true : false" :items="props.items"/>
              </template>
            </paginated-result-loader>
          </v-col>
          <v-col cols="12" :lg="6" class="d-flex flex-column">
            <div class="d-flex flex-row">
              <h2 class="card-header mb-2 flex-fill">Latest inspections</h2>
              <v-btn depressed color="white" :to="{name : 'Inspections'}" small>See all</v-btn>
            </div>
            <paginated-result-loader
                cardHeight="450"
                :disable-filters="true"
                namespace="inspectionsDashboard">
              <template v-slot:default="props">
                <inspection-list :full="false" :items="props.items"/>
              </template>
            </paginated-result-loader>
          </v-col>
          <v-col cols="12" :lg="6" class="d-flex flex-column">
            <div class="d-flex flex-row">
              <h2 class="card-header mb-2 flex-fill">Latest quotes</h2>
              <v-btn depressed color="white" :to="{name : 'Quotes'}" small>See all</v-btn>
            </div>
            <paginated-result-loader
                cardHeight="450"
                :disable-filters="true"
                namespace="quotesDashboard">

              <template v-slot:default="props">
                <quote-list :full="false" :items="props.items"/>
              </template>
            </paginated-result-loader>
          </v-col>
        </template>


      </v-row>
    </template>
  </crud-page>
</template>

<script>

import CrudPage from "../../components/CrudPage";
import ApiCountCard from "./ApiCountCard";
import PaginatedResultLoader from "../../components/PaginatedResultLoader";
import OrganisationList from "../../components/organisations/OrganisationList";
import {mapGetters} from "vuex";
import HoseList from "../../components/hoses/HoseList";
import InspectionList from "../../components/inspections/InspectionList";
import QuoteList from "../../components/quotes/QuoteList";
import ItemListLoader from "@/components/ItemListLoader.vue";

export default {
  name: 'Dashboard',
  components: {
    ItemListLoader,
    QuoteList,
    InspectionList,
    HoseList,
    OrganisationList,
    PaginatedResultLoader,
    ApiCountCard,
    CrudPage,
  },
  data() {
    return {
      selectedOrgs: []
    }
  },
  computed: {
    ...mapGetters('auth', ['clientAdmin', 'distributor', 'customer', 'organisation', 'systemAdmin']),
    currentClient() {
      return this.$store.getters['clients/item'];
    }
  },
  methods: {
    addSelectedOrgs(currentFilters, key = 'distributorOrganisationId') {
      if(this.selectedOrgs.length > 0) {
        currentFilters[key] = this.selectedOrgs
      }
      return currentFilters;
    },
    addSelectedOrgsArray(currentFilters, key = 'distributorOrganisationId') {
      if(this.selectedOrgs.length > 0) {
        currentFilters.push({key : key, value : this.selectedOrgs});
      }
      return currentFilters;
    },
    filtersString(data) {
      return encodeURI(JSON.stringify(data));
    }
  }
}
</script>
